<template>
  <div class="rich-text page">
    <div class="title" @click="handleOpenEdit(title)">
      {{ title }}
    </div>
    <div class="content" @click="handleOpenEdit(originContent, 'content')">
      {{ content }}
    </div>
  </div>
</template>

<script>
export default {
  name: "RichText",
  props: {
    title: {
      type: String,
      default: "",
    },
    originIndex: {
      type: Number,
      default: 0,
    },
    content: {
      type: String,
      default: "",
    },
    originContent: {
      type: String,
      default: "",
    }
  },
  methods: {
    handleOpenEdit(content, type = 'title') {
      this.$emit("edit-event", {
        type: "text",
        data: {
          type,
          content,
          pageIndex: this.originIndex,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.rich-text {
  height: 100%;
  width: 100%;
  position: relative;
  background-color: #fff;
  display: flex;
  flex-flow: column;

  .title,
  .content {
    border: 1px solid transparent;
    &:active {
      border: 1px solid green;
    }
  }

  .title {
    font-size: 40px;
    text-align: center;
    margin-top: 20px;
  }

  .content {
    font-size: 28px;
    margin: 20px;
    white-space: pre-wrap;
    box-sizing: border-box;
    height: 100%;
    word-break: break-all;
  }
}
</style>