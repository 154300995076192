const { host } = location

const zstpConfig = {
  projectName: '曾氏通谱',
  projectCode: 'zstp',
  BASE_URL: '//app.wczstp.com',
  primaryColor: '#AC0404',
  logo: 'logo_login',
  cover: require('@/assets/img/book.jpeg'),
}
const mmjpConfig = {
  projectName: '名门家谱',
  projectCode: 'mmjp',
  BASE_URL: 'https://mmxp.mmjp5000.com',
  primaryColor: '#CE6335',
  logo: 'logo_login_mmjp',
}
const mmxpConfig = {
  projectName: '民门修谱',
  projectCode: 'mmxp',
  BASE_URL: '//xiang.mmjp5000.com', //app.xswh5000.com',
  primaryColor: '#1358F5',
  logo: 'logo_login_mmxp',
}

const yzwhConfig = {
  projectName: '颜子文化',
  projectCode: 'yzwh',
  BASE_URL: '//yan.mmjp5000.com',
  primaryColor: '#CE6335',
  logo: 'logo_login_yzwh',
}

const jxhjConfig = {
  projectName: '江夏黄家',
  projectCode: 'jxhj',
  BASE_URL: '//hsxp.mmjp5000.com',
  primaryColor: '#CE6335',
  logo: 'logo_login_jxhj',
}

const dstpConfig = {
  projectName: '邓氏通谱',
  projectCode: 'dstp',
  BASE_URL: '//api.dsxp.mmjp5000.com',
  primaryColor: '#CE6335',
  logo: 'logo_login_dstp',
}

const nstpConfig = {
  projectName: '聂氏通谱',
  projectCode: 'nstp',
  BASE_URL: '//nie.mmjp5000.com',
  primaryColor: '#CE6335',
  logo: 'logo_login_nstp',
}

const qstpConfig = {
  projectName: '屈氏通谱',
  projectCode: 'qstp',
  BASE_URL: 'https://qstpadmin.mmjp5000.com',
  primaryColor: '#CE6335',
  logo: 'logo_login_qstp',
}

const envMap = {
  'public-zstp.mmjp5000.com': zstpConfig,
  'public-mmjp.mmjp5000.com': mmjpConfig,
  'public-minmen.mmjp5000.com': mmxpConfig,
  'public-yzwh.mmjp5000.com': yzwhConfig,
  'public-jxhj.mmjp5000.com': jxhjConfig,
  'public-dstp.mmjp5000.com': dstpConfig,
  'public-nstp.mmjp5000.com': nstpConfig,
  'public-qstp.mmjp5000.com': qstpConfig,
}

const env = envMap[host] || mmjpConfig

export default env
