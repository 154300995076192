<template>
  <div class="catalog page" :class="{ 'is-a3': isA3, 'is-first': idx === 0 }">
    <h3 class="index-title" v-if="isA3 && idx === 0 || !isA3">目录</h3>
    <div class="index-content">
      <div class="index-line" v-for="(item, index) in catalogArr" :key="index">
        <div class="index-line-name">{{ item.title }}</div>
        <div class="dash"></div>
        <div class="index-line-num">{{ getChinesePage(item.page) }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import numberToChinese from "number-to-chinese-words";

export default {
  name: "Catalog",
  props: {
    catalogArr: {
      type: Array,
      default: () => [],
    },
    isA3: {
      type: Boolean,
      default: false,
    },
    idx: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    getChinesePage(page) {
      return numberToChinese.toWords(page);
    },
  },
};
</script>

<style lang="scss" scoped>
.catalog {
  height: 100%;
  width: 100%;
  position: relative;
  background-color: #fff;
  // transform: scale(0.738) translateY(15px);
  // border: 2px solid #333;
  overflow: hidden;
  box-sizing: border-box;
  // background-color: #ddd;
  // display: flex;
  // flex-flow: column nowrap;
  // justify-content: center;
  // align-items: center;

  .index-title {
    font-size: 32px;
    text-align: center;
  }

  .index-content {
    margin: 35px;
    margin-top: 60px;

    .index-line {
      width: 100%;
      display: flex;
      justify-content: space-between;
      font-size: 24px;
      margin-top: 36px;
    }

    .dash {
      border-bottom: 1px dashed #333;
      flex: 1;
      position: relative;
      top: -13px;
      margin: 0 20px;
    }
  }

  &.is-a3 {
    display: flex;
    flex-flow: row-reverse nowrap;
    align-items: flex-start;
    padding: 10px 0;
    // background-color: #ddd;

    .index-title {
      writing-mode: vertical-lr;
      font-size: 52px;
      font-weight: 400;
      margin-left: 0;
      // margin-top: 30px;
    }

    .index-content {
      display: flex;
      flex-flow: row-reverse nowrap;
      align-items: flex-start;
      justify-content: flex-start;
      // height: calc(100% - 60px);
      height: 100%;
      // margin: 30px 0;
      margin: 0;
      flex: 1;
      box-sizing: border-box;

      .index-line-name {
        writing-mode: vertical-lr;
        padding-bottom: 30px;
      }

      .index-line {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        height: 100%;
        margin-top: 0;
        width: calc(100% / 12);
      }

      .dash {
        border-right: 1px dashed #333;
        flex: 1;
        position: relative;
        // left: -13px;
        margin: 0 20px;
      }

      .index-line-num {
        writing-mode: vertical-lr;
        padding-bottom: 30px;
      }
    }

    &.is-first {
      .index-line {
        width: calc(100% / 10);
      }
    }
  }
}
</style>