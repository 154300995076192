<template>
  <div class="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
  beforeCreate() {
    if (this.$route.query.token) {
      console.log('has token')
      localStorage.setItem('token', this.$route.query.token)
    }
  },
}
</script>

<style lang="scss">
html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  touch-action: manipulation;
}

body {
  font-size: 28px;
  font-family: 'Source Han Serif SC VF', serif !important;
}

.book * {
  box-sizing: border-box;
}

.app {
  height: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
  background-color: #fff;
}

body::-webkit-scrollbar {
  display: none;
}
</style>
