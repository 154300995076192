<template>
  <div class="oushi-diaoxian page">
    <div class="wrapper">
      <div class="factions">
        <div class="faction-wrapper" v-for="(item, index) in grid.factions" :key="index">
          <div class="faction">
            <span>{{ item }}</span>
          </div>
        </div>
      </div>
      <div class="tree-content" :style="{ transform: `translateY(${0 - (grid.offset * 92)}%) rotateY(${grid.isReverse ? '180deg' : '0deg'})` }">
        <oushi-diaoxian-item v-for="(item, index) in grid.tree" :key="index" :item="item" :isFirst="true" />
      </div>
    </div>

    <!-- <div v-for="(item, index) in grid" :key="index" class="grid">
      <div class="faction inner">
        <span>{{ splitText(item.name) }}</span>
      </div> -->
    <!-- <div
        class="inner name-wrapper"
        v-for="(person, index) in item.list"
        :style="`${index !== 0 && item.list[index - 1].child ? `margin-right: ${item.list[index - 1].child.length * 47 + (item.list[index - 1].spouseArrs ? item.list[index - 1].spouseArrs.length * 7 : 0)}px` : ''}`"
        :key="index"
      >
        <div class="name">
          <div
            class="has-father"
            :class="{
              'has-father-left':
                index !== 0 && isSameFather(person, item.list[index - 1]),
            }"
            
            v-if="person.is_zeng === 1 && person.father_id"
          ></div>
          {{ person.is_zeng === 1 ? person.name : person.username }}
          <div
            class="has-son"
            v-if="person.is_zeng === 1 && person.child && person.child.length"
          ></div>
        </div>
        <div
          class="name"
          v-for="(spouse, index) in person.spouseArrs"
          :key="index"
        >
          {{ spouse.is_zeng === 1 ? spouse.name : spouse.username }}
        </div>
      </div> -->
    <!-- </div> -->
  </div>
</template>

<script>
import OushiDiaoxianItem from "./OushiDiaoxianItem.vue";

export default {
  name: "Oushi-Diaoxian",
  components: {
    OushiDiaoxianItem,
  },
  props: {
    grid: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    emptyArr() {
      return new Array(10 - this.grid.length).fill(0).map((item, index) => {});
    },
  },
  methods: {
    splitText(str) {
      if (!str) return "";
      let strArr = str.split("");
      let offset = 0;
      while (offset < str.length) {
        offset += 2;
        strArr.splice(offset + Math.floor(offset / 2) - 1, 0, "\n");
      }
      return strArr.join("");
    },
    isSameFather(item1, item2) {
      return item1.father_id === item2.father_id;
    },
  },
};
</script>

<style lang="scss" scoped>
.oushi-diaoxian {
  // height: calc(100% - 20px);
  // width: calc(100% - 20px);
  height: 100%;
  width: 100%;
  position: relative;
  background-color: #fff;
  display: flex;
  flex-flow: column nowrap;
  // margin: 20px;
  box-sizing: border-box;
  overflow: hidden;

  .wrapper {
    padding: 20px;
  }

  .factions {
    display: flex;
    flex-flow: row nowrap;
    width: 100%;

    .faction-wrapper {
      width: calc(100% / 5);
      display: flex;
      justify-content: center;
    }

    .faction {
      display: flex;
      justify-content: center;
      align-items: center;
      flex: none;
      text-align: left;
      white-space: pre;
      width: 100px;
      height: 47px;
      border: 1px solid #ac0404;
      border-radius: 7px;
      font-size: 24px;
      font-weight: 400;
      color: #ac0404;
    }
  }

  .tree-content {
    position: relative;
    // padding: 0 20px;
    width: calc(100% - 40px);
    margin-left: 40px;
    // padding-top: 50px;
  }
}
</style>