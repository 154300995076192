<template>
  <div class="sushi page">
    <div v-for="(item, index) in grid" :key="index" class="grid">
      <div class="faction inner">
        <span>{{ splitText(item.factionsName) }}</span>
      </div>
      <div class="sign inner">
        <span>{{ getSign(item) }}</span>
      </div>
      <div class="name inner">
        <span>{{ item.is_zeng === 1 ? item.name : item.username }}</span>
      </div>
      <div class="desc inner">
        <span>{{ item.des }}</span>
      </div>
    </div>
    <div class="grid" v-for="(item, index) in emptyArr" :key="`empty_${index}`">
      <div class="faction inner">
        <span></span>
      </div>
      <div class="sign inner">
        <span></span>
      </div>
      <div class="name inner">
        <span></span>
      </div>
      <div class="desc inner">
        <span></span>
      </div>
    </div>
  </div>
</template>

<script>
import numberToChinese from "number-to-chinese-words";

export default {
  name: "Sushi",
  props: {
    grid: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    emptyArr() {
      return new Array(10 - this.grid.length).fill(0).map((item, index) => {});
    },
  },
  methods: {
    getSign(item) {
      if (item.sign) return item.sign;
      if (!item.id) return "";
      if (item.is_zeng === 1) {
        const sex = item.sex === 1 ? "子" : "女";
        if (!item.rank_id || item.isOnly)
          return item.father_id ? `${item.fatherName}\n之${sex}` : "开基祖";
        // 直系
        const rank =
          item.rank_id === 1
            ? "长"
            : item.rank_id === 2
            ? "次"
            : numberToChinese.toWords(item.rank_id);
        return item.fatherName ? `${item.fatherName}\n${rank}${sex}` : "";
      } else {
        return item.sex === 1 ? "适" : item.is_live ? "配" : "妣";
      }
    },
    splitText(str) {
      if (!str) return "";
      let strArr = str.split("");
      let offset = 0;
      while (offset < str.length) {
        offset += 2;
        strArr.splice(offset + Math.floor(offset / 2) - 1, 0, "\n");
      }
      return strArr.join("");
    },
  },
};
</script>

<style lang="scss" scoped>
.sushi {
  height: 100%;
  width: 100%;
  position: relative;
  background-color: #fff;
  display: flex;
  flex-flow: row-reverse nowrap;

  .grid {
    background-color: #fff;
    width: 10%;
    height: 100%;
    border-left: 1px solid #b5b5b5;
    color: #333333;
    display: flex;
    flex-flow: column nowrap;

    &:last-of-type {
      border-left: none;
    }

    .inner {
      border-bottom: 1px solid #b5b5b5;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      flex: none;
    }

    .faction {
      height: 67px;
      font-size: 20px;
      text-align: left;
      white-space: pre;
      writing-mode: vertical-rl;
    }

    .sign {
      height: 80px;
      font-size: 20px;
      writing-mode: vertical-rl;
      // display: inline-block;
      text-align: center;
      white-space: pre;
      line-height: 1;
      // line-height: 80px;
    }

    .name {
      height: 118px;
      font-size: 32px;
      font-weight: 600;
      writing-mode: vertical-rl;
    }

    .desc {
      // height: calc(100% - 118px - 80px - 67px);
      border-bottom: none;
      writing-mode: vertical-rl;
      padding: 10px;
      box-sizing: border-box;
      overflow: hidden;
      font-size: 20px;
      justify-content: start;
      flex: 1;
      // box-sizing: border-box;
    }
  }
}
</style>