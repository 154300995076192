<template>
  <div class="oushi-a3 page">
    <div class="left-page">
      <div class="before">
        <div
          class="faction-name"
          :style="`height: ${item.length * 88}px`"
          v-for="(item, index) in groupFactions[0]"
          :key="index"
        >
          <span ref="factionName">{{ index }}</span>
        </div>
      </div>
      <div class="after">
        <div
          v-for="(item, index) in grid.slice(0, 12)"
          :key="index"
          class="grid"
        >
          <!-- <div class="faction inner">
        <span>{{ splitText(item.factionsName) }}</span>
      </div> -->
          <div class="sign inner">
            <span>{{ getSign(item) }}</span>
          </div>
          <div class="name inner">
            <span ref="name">{{
              item.is_zeng === 1 ? item.name : item.username
            }}</span>
          </div>
          <div class="desc inner">
            <span>{{ item.des }}</span>
          </div>
        </div>
        <div
          class="grid"
          v-for="(item, index) in emptyArr"
          :key="`empty_${index}`"
        >
          <div class="sign inner">
            <span></span>
          </div>
          <div class="name inner">
            <span ref="name"></span>
          </div>
          <div class="desc inner">
            <span></span>
          </div>
        </div>
      </div>
    </div>
    <pu-info class="pu-info" :style="`width: 6%`" :puInfo="puInfo" />
    <div class="right-page">
      <div class="before">
        <div
          class="faction-name"
          :style="`height: ${item.length * 88}px`"
          v-for="(item, index) in groupFactions[1]"
          :key="index"
        >
          {{ index }}
        </div>
      </div>
      <div class="after">
        <div v-for="(item, index) in grid.slice(12)" :key="index" class="grid">
          <!-- <div class="faction inner">
        <span>{{ splitText(item.factionsName) }}</span>
      </div> -->
          <div class="sign inner">
            <span>{{ getSign(item) }}</span>
          </div>
          <div class="name inner">
            <span ref="name">{{
              item.is_zeng === 1 ? item.name : item.username
            }}</span>
          </div>
          <div class="desc inner">
            <span>{{ item.des }}</span>
          </div>
        </div>
        <div
          class="grid"
          v-for="(item, index) in emptyArr"
          :key="`empty_${index}`"
        >
          <div class="sign inner">
            <span></span>
          </div>
          <div class="name inner">
            <span ref="name"></span>
          </div>
          <div class="desc inner">
            <span></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import numberToChinese from "number-to-chinese-words";
import PuInfo from "./PuInfo.vue";

export default {
  name: "OushiA3",
  components: {
    PuInfo,
  },
  props: {
    grid: {
      type: Array,
      default: () => [],
    },
    puInfo: {
      type: Object,
      default: () => ({}),
    },
    factions: {
      type: Array,
      default: () => [],
    },
    index: {
      type: Number,
      default: 0,
    },
    pageIndex: {
      type: [Number, String],
      default: 0,
    },
    levelUnit: {
      type: String,
      default: "派",
    },
    levelOffset: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    groupFactions() {
      return [
        this.factions.slice(0, 12).reduce((acc, cur) => {
          const factionName = `${numberToChinese.toWords(
            cur + this.levelOffset
          )}${this.levelUnit}`;
          if (acc[factionName]) {
            acc[factionName].length += 1;
          } else {
            acc[factionName] = { length: 1 };
          }
          return acc;
        }, {}),
        this.factions.slice(12).reduce((acc, cur) => {
          const factionName = `${numberToChinese.toWords(
            cur + this.levelOffset
          )}${this.levelUnit}`;
          if (acc[factionName]) {
            acc[factionName].length += 1;
          } else {
            acc[factionName] = { length: 1 };
          }
          return acc;
        }, {}),
      ];
    },
    gridArr() {
      return this.grid.concat(this.emptyArr);
    },
    emptyArr() {
      return new Array(24 - this.grid.length)
        .fill(0)
        .map((item, index) => ({}));
    },
  },
  watch: {
    pageIndex() {
      if (this.pageIndex === this.index + 1) {
        this.$nextTick(() => {
          this.autoModifyFontSize(this.$refs.name);
        });
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.autoModifyFontSize(this.$refs.name);
      this.autoModifyFontSize(this.$refs.factionName);
    });
  },
  methods: {
    getSign(item) {
      if (item.sign) {
        if (item.sign === '抚子' && item.father_name) {
          return `${item.father_name}\n${item.sign}`
        }
        return item.sign;
      }
      if (!item.id) return "";
      if (item.is_zeng === 1) {
        const sex = item.sex === 1 ? "子" : "女";
        if (!item.rank_id || item.isOnly)
          return item.father_name ? `${item.father_name}\n之${sex}` : "";
        // 直系
        const rank =
          item.rank_id === 1
            ? "长"
            : item.rank_id === 2
            ? "次"
            : numberToChinese.toWords(item.rank_id);
        return item.father_name ? `${item.father_name}\n${rank}${sex}` : "";
      } else {
        const liveTitle = this.$route.query.liveTitle || "配";
        const deadTitle = this.$route.query.deadTitle || "妣";
        return item.sex === 1 ? "适" : item.is_live ? liveTitle : deadTitle;
      }
    },
    splitText(str) {
      if (!str) return "";
      let strArr = str.split("");
      let offset = 0;
      while (offset < str.length) {
        offset += 2;
        strArr.splice(offset + Math.floor(offset / 2) - 1, 0, "\n");
      }
      return strArr.join("");
    },
    autoModifyFontSize(elList) {
      elList && elList.forEach((el) => {
        const textWidth = el.offsetWidth;
        const parentWidth = el.parentNode.offsetWidth;
        if (textWidth > parentWidth) {
          el.style.fontSize = `${(parentWidth / textWidth) * 26}px`;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.oushi-a3 {
  height: 100%;
  width: 100%;
  position: relative;
  background-color: #fff;
  display: flex;
  // transform: scale(0.738) translateY(15px);
  border: 3px solid #000;
  overflow: hidden;

  .left-page,
  .right-page {
    display: flex;
    width: 47%;
    height: 1060px;
    flex: none;
  }

  .before,
  .after {
    position: relative;
    display: flex;
    flex-flow: column nowrap;
    height: 1060px;
  }

  .before {
    width: 67px;
    // height: 100%;
  }

  .after {
    flex: 1;
  }

  .faction-name {
    width: 67px;
    box-sizing: border-box;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    writing-mode: vertical-lr;
    border-bottom: 3px solid #000;
    font-size: 34px;

    &:last-of-type {
      border-bottom: none;
    }
  }

  .grid {
    background-color: #fff;
    width: 100%;
    height: 88px;
    flex: none;
    box-sizing: border-box;
    display: flex;
    border-bottom: 3px solid #000;
    border-left: 3px solid #000;
    color: #000;

    &:last-of-type,
    &:nth-of-type(12) {
      border-bottom: none;
    }

    .inner {
      border-right: 3px solid #000;
      display: flex;
      height: 100%;

      &:last-of-type {
        border-right: none;
      }
    }

    .sign {
      width: 66px;
      font-size: 20px;
      display: inline-block;
      // writing-mode: vertical-lr;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      line-height: 1;
      white-space: pre-wrap;
    }

    .name {
      width: 80px;
      font-size: 32px;
      font-weight: 600;
      display: flex;
      justify-content: center;
      align-items: center;
      white-space: nowrap;
    }

    .desc {
      // height: calc(100% - 118px - 80px - 67px);
      // border-bottom: none;
      // writing-mode: vertical-rl;
      padding: 10px;
      box-sizing: border-box;
      overflow: hidden;
      font-size: 20px;
      flex: 1;
      line-height: 1.6;
      // justify-content: start;
      // box-sizing: border-box;
    }
  }
}
</style>