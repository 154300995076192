<template>
  <div
    class="sushi-diaoxian-item"
    :class="{ 'is-reverse': isReverse }"
  >
    <div
      class="name-wrapper"
      :class="{ 'is-first': isFirst }"
      :style="`--border-width: ${leftWidth}px`"
    >
      <div class="name">
        <div
          class="has-father"
          :class="{
            'has-father-left': index !== 0,
          }"
          v-if="item.is_zeng === 1 && item.father_id"
        >
          <div class="mask"></div>
        </div>
        <div class="name-text">
          {{ item.is_zeng === 1 ? (item.name || item.puming) : item.username }}
        </div>
        <!-- {{ this.childLeftWidth.join(',') || 0 }} -->
        <!-- {{ leftWidth }} {{ childWidth }} -->
        <div
          class="has-son"
          v-if="item.is_zeng === 1 && item.child && item.child.length"
        ></div>
      </div>
      <!-- <div class="name" v-for="(spouse, index) in item.spouseArrs" :key="index">
        {{ spouse.is_zeng === 1 ? spouse.name : spouse.username }}
      </div> -->
    </div>
    <div v-if="item.gridChild" class="children">
      <sushi-diaoxian-item
        v-for="(subItem, index) in item.gridChild"
        ref="children"
        :isA3="isA3"
        :index="index"
        :isLast="index === item.gridChild.length - 1"
        :isReverse="isReverse"
        :key="index"
        :item="subItem"
        @update-left-width="updateLeftWidth"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "sushi-diaoxian-item",
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    isA3: {
      type: Boolean,
      default: false,
    },
    index: {
      type: Number,
      default: 0,
    },
    isFirst: {
      type: Boolean,
      default: false,
    },
    isReverse: {
      type: Boolean,
      default: false,
    },
    isLast: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      leftWidth: 67.5,
      childLeftWidth: [],
      originLeftWidth: 67.5,
      leftSpouseNum: 0,
    };
  },
  computed: {
    // emptyArr() {
    //   return new Array(10 - this.grid.length).fill(0).map((item, index) => {});
    // },
    childWidth() {
      return this.childLeftWidth.reduce((prev, curr) => prev + curr, 0);
    },
  },
  created() {
    this.leftWidth = this.index === 0 ? 0 : 67.5;
    this.originLeftWidth = this.index === 0 ? 0 : 67.5;
  },
  watch: {
    childWidth() {
      this.calcLeftWidth();
      this.$emit("update-left-width", {
        index: this.index,
        width: this.leftWidth + (this.isLast ? this.childWidth : 0),
        // childWidth: this.childWidth,
        // leftSpouseNum: this.leftSpouseArrs.length + this.leftSpouseNum,
      });
    },
  },
  mounted() {
    // this.leftWidth = this.leftWidth + (this.item.sposeArrs ? this.item.sposeArrs.length * 0.2 : 0);
    // const leftWidth = 7 + (this.item.spouseArrs ? this.item.spouseArrs.length * 0.2 : 0);
    // console.log(leftWidth)
    this.calcLeftWidth();

    this.$emit("update-left-width", {
      index: this.index,
      width: this.leftWidth + (this.isLast ? this.childWidth : 0),
      // childWidth: this.childWidth,
      // leftSpouseNum: this.leftSpouseArrs.length + this.leftSpouseNum,
    });
    // console.log(leftBrother && leftBrother.childLeftWidth.reduce((prev, curr) => prev + curr, 0))
  },
  methods: {
    isSameFather(item1, item2) {
      return item1.father_id === item2.father_id;
    },
    handleChildWidthChange({ index, width }) {
      // const leftBrother = this.index === 0 ? null : this.$parent.$refs.children[this.index - 1];
      const nextBrother =
        this.$parent.$refs.children &&
        this.$parent.$refs.children[this.index + 1];
      if (nextBrother) {
        nextBrother.updateLeftWidth({ index: index, width: width });
      }
    },
    updateLeftWidth({ index, width, childWidth, leftSpouseNum }) {
      // console.log(width)
      // this.childLeftWidth.splice(index, 1, width);
      // this.childLeftWidth[index] = width;
      this.$set(this.childLeftWidth, index, width);
      // const leftWidth = this.childLeftWidth.reduce((prev, curr) => prev + curr, 0);
      // const beforeBrother = this.index === 0 ? null : this.$parent.$refs.children && this.$parent.$refs.children[this.index - 1];
      // console.log(this.childLeftWidth)
      // this.leftWidth = leftWidth + this.originLeftWidth + beforeBrother.leftWidth
      // this.$emit("update-left-width", {
      //   index: this.index,
      //   width: this.leftWidth,
      // });
    },
    getChildWidth(component) {
      let width = 0;

      if (component.childLeftWidth.length > 1) {
        width += component.childLeftWidth.reduce(
          (prev, curr) => prev + curr,
          0
        );
      } else if (component.childLeftWidth.length === 1) {
        width += this.getChildWidth(component.$refs.children[0]);
      }
      // if (component.$refs.children) {
      //   component.$refs.children.forEach((child) => {
      //     width += child.index === 0 ? 0 :this.getChildWidth(child);
      //   });
      // } else {
      //   width += 9;
      // }
      return width;
    },
    calcLeftWidth() {
      const beforeBrother =
        this.index === 0
          ? null
          : this.$parent.$refs.children &&
            this.$parent.$refs.children[this.index - 1];
      const afterBrother =
        this.$parent.$refs.children &&
        this.$parent.$refs.children[this.index + 1];
      let leftBrotherWidth = 0;
      let rightBrotherWidth = 0;
      if (beforeBrother) {
        // beforeBrother.updateLeftWidth({ index: this.index, width: this.leftWidth });
        // console.log(beforeBrother.childLeftWidth.reverse()[0])
        // const lastLength = beforeBrother.childLeftWidth.slice(-1)[0] || 0;

        // this.leftWidth =
        //   this.originLeftWidth + beforeBrother.leftWidth + lastLength - 3.5;
        // leftBrotherWidth = beforeBrother.childLeftWidth
        //   ? beforeBrother.childWidth
        //   : 0;

        // if (
        //   beforeBrother.childLeftWidth.length === 1 &&
        //   beforeBrother.childLeftWidth[0] === 0
        // ) {
        //   leftBrotherWidth += beforeBrother.$refs.children[0].childWidth
        // }

        leftBrotherWidth = this.getChildWidth(beforeBrother);
      }

      // if (afterBrother) {
      //   rightBrotherWidth = this.getChildWidth(afterBrother);
      //   console.log(rightBrotherWidth);
      // }

      // if (!rightBrother) {
      //   rightBrotherWidth = this.childWidth;
      // }

      this.leftWidth = this.index === 0 ? 0 : 67.5 + leftBrotherWidth;

      if (this.leftWidth > 1125) {
        this.leftWidth = this.leftWidth - 7.5;
      }

      return this.leftWidth;
    },
  },
};
</script>

<style lang="scss" scoped>
.sushi-diaoxian-item {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-end;
  // box-sizing: border-box;
  // position: relative;
  // right: 0px;
  &.is-reverse {
    // align-items: flex-start;
  }

  &.is-a3 {
    .name-wrapper {
      // height: 100%;
      min-height: 2em;
      font-size: 16px;
      margin-right: 24px;
      margin-top: 45px;
      display: flex;
      align-items: flex-start;
      flex-flow: row-reverse nowrap;

      &.is-first {
        margin-top: 0;
      }
    }

    .name {
      // height: 118px;
      position: relative;
      margin-right: 3.5px;
      // text-align: center;

      &:first-of-type {
        margin-right: 0;
      }
      line-height: 1;
      // width: 18px;
      // height: 41px;
      font-size: 10px;
      // font-family: PingFang SC;
      font-weight: 600;
      color: #ac0404;
      writing-mode: vertical-rl;
    }

    .has-father {
      width: 7px;
      height: 7px;
      background: #ffffff;
      border-color: #ac0404;
      border: 1px solid #ac0404;
      z-index: 20;
      border-radius: 50%;
      position: absolute;
      top: -10px;
      left: 50%;
      transform: translateX(-50%);
      // overflow: hidden;
      .mask {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: 10;
        border-radius: 50%;
        background-color: #fff;
      }

      &.has-father-left {
        &::before {
          content: "";
          display: block;
          width: var(--border-width);
          height: 1px;
          background: #ac0404;
          position: absolute;
          left: 3px;
          bottom: 23px;
          transform: translateX(-0.5%);
        }
      }

      &::after {
        content: "";
        display: block;
        width: 1px;
        height: 24px;
        background: #ac0404;
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translateX(-50%);
      }
    }

    .has-son {
      content: "";
      display: block;
      width: 1px;
      height: 24px;
      background: #ac0404;
      position: absolute;
      left: 50%;
      top: 26px;
      transform: translateX(-45%);
    }
  }

  // .is-a3 {
  //   .name-wrapper {
  //     font-size: 16px;
  //     margin-right: 24px;
  //     margin-top: 45px;
  //   }

  //   .name {
  //     font-size: 10px;
  //     margin-right: 3.5px;
  //   }

  //   .has-father {
  //     height: 7px;
  //     width: 7px;
  //     top: -10px;
  //     border: 1px solid #ac0404;

  //     &.has-father-left {
  //       &::before {
  //         content: "";
  //         display: block;
  //         width: var(--border-width);
  //         height: 1px;
  //         background: #ac0404;
  //         position: absolute;
  //         left: 2px;
  //         bottom: 23px;
  //         transform: translateX(-0.5%);
  //       }
  //     }

  //     &::after {
  //       content: "";
  //       display: block;
  //       width: 1px;
  //       height: 24px;
  //       background: #ac0404;
  //       position: absolute;
  //       left: 50%;
  //       bottom: 0;
  //       transform: translateX(-50%);
  //     }
  //   }

  //   .has-son {
  //     content: "";
  //     display: block;
  //     width: 1px;
  //     height: 24px;
  //     background: #ac0404;
  //     position: absolute;
  //     left: 50%;
  //     top: 26px;
  //     transform: translateX(-45%);
  //   }
  // }
}

.name-wrapper {
  // height: 100%;
  min-height: 2em;
  font-size: 32px;
  margin-right: 47px;
  margin-top: 90px;
  display: flex;
  align-items: flex-start;
  flex-flow: row-reverse nowrap;

  &.is-first {
    margin-top: 0;
  }
}

.name {
  // height: 118px;
  position: relative;
  margin-right: 7px;
  // text-align: center;

  &:first-of-type {
    margin-right: 0;
  }
  line-height: 1;
  // width: 18px;
  // height: 41px;
  font-size: 20px;
  // font-family: PingFang SC;
  font-weight: 600;
  color: #ac0404;
  writing-mode: vertical-rl;
}

.has-father {
  width: 14px;
  height: 14px;
  background: #ffffff;
  border-color: #ac0404;
  border: 2px solid #ac0404;
  z-index: 20;
  border-radius: 50%;
  position: absolute;
  top: -20px;
  left: 50%;
  transform: translateX(-50%);
  // overflow: hidden;
  .mask {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 10;
    border-radius: 50%;
    background-color: #fff;
  }

  &.has-father-left {
    &::before {
      content: "";
      display: block;
      width: var(--border-width);
      height: 2px;
      background: #ac0404;
      position: absolute;
      left: 4px;
      bottom: 46px;
      transform: translateX(-0.5%);
    }
  }

  &::after {
    content: "";
    display: block;
    width: 2px;
    height: 48px;
    background: #ac0404;
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
  }
}

.has-son {
  content: "";
  display: block;
  width: 2px;
  height: 48px;
  background: #ac0404;
  position: absolute;
  left: 50%;
  top: 52px;
  transform: translateX(-45%);
}

.children {
  display: flex;
  align-items: flex-start;
  flex-flow: row-reverse nowrap;
}

.is-reverse {
  .name-text {
    transform: rotateY(180deg);
  }
  // .has-father {
  //   &.has-father-left {
  //     &::before {
  //       content: "";
  //       display: block;
  //       width: var(--border-width);
  //       height:2px;
  //       background: #ac0404;
  //       position: absolute;
  //       right: 4px;
  //       bottom: 46px;
  //       transform: translateX(0.5%);
  //     }
  //   }
  // }
  // .children {
  //   flex-flow: row nowrap;
  // }
}
</style>