<template>
  <div class="oushi page">
    <div class="before">
      <div
        class="faction"
        :style="`height: ${item.length * 12.5}%`"
        v-for="(item, index) in groupFactions"
        :key="index"
      >
        {{ index }}
      </div>
    </div>
    <div class="after">
      <div v-for="(item, index) in grid" :key="index" class="grid">
        <!-- <div class="faction inner">
        <span>{{ splitText(item.factionsName) }}</span>
      </div> -->
        <div class="sign inner">
          <span>{{ getSign(item) }}</span>
        </div>
        <div class="name inner">
          <span ref="name">{{
            item.is_zeng === 1 ? item.name : item.username
          }}</span>
        </div>
        <div class="desc inner">
          <span>{{ item.des }}</span>
        </div>
      </div>
      <div
        class="grid"
        v-for="(item, index) in emptyArr"
        :key="`empty_${index}`"
      >
        <div class="sign inner">
          <span></span>
        </div>
        <div class="name inner">
          <span ref="name"></span>
        </div>
        <div class="desc inner">
          <span></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import numberToChinese from "number-to-chinese-words";

export default {
  name: "Oushi",
  props: {
    grid: {
      type: Array,
      default: () => [],
    },
    factions: {
      type: Array,
      default: () => [],
    },
    index: {
      type: Number,
      default: 0,
    },
    pageIndex: {
      type: [Number, String],
      default: 0,
    },
  },
  computed: {
    groupFactions() {
      return this.factions.reduce((acc, cur) => {
        const factionName = `${numberToChinese.toWords(cur)}派`;
        if (acc[factionName]) {
          acc[factionName].length += 1;
        } else {
          acc[factionName] = { length: 1 };
        }
        return acc;
      }, {});
    },
    emptyArr() {
      return new Array(10 - this.grid.length).fill(0).map((item, index) => {});
    },
  },
  watch: {
    pageIndex() {
      if (this.pageIndex === this.index + 1) {
        this.$nextTick(() => {
          this.autoModifyFontSize(this.$refs.name);
        });
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.autoModifyFontSize(this.$refs.name);
    });
  },
  methods: {
    getSign(item) {
      if (item.sign) return item.sign;
      if (!item.id) return "";
      if (item.is_zeng === 1) {
        const sex = item.sex === 1 ? "子" : "女";
        if (!item.rank_id || item.isOnly)
          return item.father_id ? `${item.fatherName}\n之${sex}` : "开基祖";
        // 直系
        const rank =
          item.rank_id === 1
            ? "长"
            : item.rank_id === 2
            ? "次"
            : numberToChinese.toWords(item.rank_id);
        return item.fatherName ? `${item.fatherName}${rank}${sex}` : "";
      } else {
        return item.sex === 1 ? "适" : item.is_live ? "配" : "妣";
      }
    },
    splitText(str) {
      if (!str) return "";
      let strArr = str.split("");
      let offset = 0;
      while (offset < str.length) {
        offset += 2;
        strArr.splice(offset + Math.floor(offset / 2) - 1, 0, "\n");
      }
      return strArr.join("");
    },
    autoModifyFontSize(elList) {
      elList.forEach((el) => {
        const textWidth = el.offsetWidth;
        const parentWidth = el.parentNode.offsetWidth;
        if (textWidth > parentWidth) {
          el.style.fontSize = `${(parentWidth / textWidth) * 10}px`;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.oushi {
  height: 100%;
  width: 100%;
  position: relative;
  background-color: #fff;
  display: flex;

  .before,
  .after {
    display: flex;
    flex-flow: column nowrap;
  }

  .before {
    width: 67px;
  }

  .after {
    flex: 1;
  }

  .faction {
    width: 67px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    writing-mode: vertical-lr;
    border-bottom: 1px solid #b5b5b5;

    &:last-of-type {
      border-bottom: none;
    }
  }

  .grid {
    background-color: #fff;
    width: 100%;
    height: 12.5%;
    display: flex;
    border-bottom: 1px solid #b5b5b5;
    border-left: 1px solid #b5b5b5;
    color: #333333;

    &:last-of-type {
      border-bottom: none;
    }

    .inner {
      border-right: 1px solid #b5b5b5;
      display: flex;
      height: 100%;

      &:last-of-type {
        border-right: none;
      }
    }

    .sign {
      width: 66px;
      font-size: 20px;
      display: inline-block;
      writing-mode: vertical-lr;
      text-align: center;
      line-height: 66px;
    }

    .name {
      width: 80px;
      font-size: 32px;
      font-weight: 600;
      display: flex;
      justify-content: center;
      align-items: center;
      white-space: nowrap;
    }

    .desc {
      // height: calc(100% - 118px - 80px - 67px);
      // border-bottom: none;
      // writing-mode: vertical-rl;
      padding: 18px;
      box-sizing: border-box;
      overflow: hidden;
      font-size: 20px;
      flex: 1;
      line-height: 1.6;
      // justify-content: start;
      // box-sizing: border-box;
    }
  }
}
</style>