<template>
  <div class="page" :class="{ 'is-a3': isA3 }">
    <catalog v-for="(item, index) in catalogArr" :key="index" :idx="index" :catalogArr="item" :isA3="isA3" />
  </div>
</template>

<script>
import Catalog from "@/components/BookPc/Catalog.vue";
import { chunk } from 'lodash'
export default {
  name: "BookPcMulu",
  components: {
    Catalog,
  },
  data() {
    return {
      catalogArr: [],
      isA3: this.$route.query.isA3
    };
  },
  mounted() {
    const mulu = JSON.parse(this.$route.query.catalogArr.replace(/\\/g, '') || '[]')
    const muluData = mulu.reduce((acc, cur, index) => {
        const { title, size } = cur;
        const page = acc[index - 1] ? acc[index - 1].page + acc[index - 1].size : 1;
        // if (acc[cur.title]) {
        //   acc[cur.title].size += 1;
        // } else {
        //   acc[cur.title] = { page: 1 };
        // }
        acc = [...acc, { title, size, page }];
        return acc;
      }, [])
    if (this.isA3) {
      this.catalogArr = [muluData.slice(0, 10), ...chunk(muluData.slice(10), 12)];
    } else {
      this.catalogArr = chunk(muluData, 14);
    }
  }
};
</script>

<style lang="scss" scoped>
.page {
  width: 726px;
  height: 1064px;
  margin: 0;
  padding: 0;
  position: relative;

  &.is-a3 {
    height: 1182px;
    // border-bottom: 1px solid #000;
  }
}
</style>