var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sushi-diaoxian page"},[_c('div',{staticClass:"wrapper",staticStyle:{"border-right":"none"}},[(_vm.grid[1])?[(_vm.type == 3)?_c('div',{staticClass:"factions"},_vm._l((_vm.grid[1].factions),function(item,index){return _c('div',{key:index,staticClass:"faction-wrapper"},[_c('div',{staticClass:"faction"},[_c('span',[_vm._v(_vm._s(item))])])])}),0):_vm._e(),_c('div',{staticClass:"content"},[(_vm.type == 3)?_c('div',{staticClass:"tree-content",class:{ zhixi: _vm.type },style:({
            transform: ("translateX(" + (_vm.type === 3 ? ((_vm.grid[1].offset * 100) + "%") : ("calc(" + (_vm.grid[1].offset * 100) + "% - " + (_vm.grid[1].offset * 70) + "px)")) + ") rotateY(0deg)"),
          })},_vm._l((_vm.grid[1].list),function(item,index){return _c('sushi-diaoxian-item-zhixi',{key:item.id,style:({
              height: "calc(100% / 7)",
            }),attrs:{"list":item.list,"fatherIndex":index === 0
                ? -1
                : _vm.getFatherPosition(_vm.grid[1].list[index - 1].list),"isFirst":true}})}),1):_c('div',{staticClass:"tree-content left",style:({
            transform: ("translateX(" + (_vm.type === 3 ? ((_vm.grid[1].offset * 100) + "%") : ("calc(" + (_vm.grid[1].offset * 100) + "% - " + (_vm.grid[1].offset * 70) + "px)")) + ") rotateY(0deg)"),
          })},_vm._l((_vm.grid[1].tree),function(item,index){return _c('sushi-diaoxian-item',{key:index,attrs:{"item":item,"isFirst":true,"isReverse":_vm.grid[1].isReverse}})}),1)])]:_vm._e()],2),_c('pu-info',{staticClass:"pu-info",style:("width: 6%"),attrs:{"puInfo":_vm.puInfo}}),_c('div',{staticClass:"wrapper",staticStyle:{"border-left":"none"}},[(_vm.grid[0])?[_c('div',{staticClass:"factions"},_vm._l((_vm.grid[0].factions),function(item,index){return _c('div',{key:index,staticClass:"faction-wrapper"},[_c('div',{staticClass:"faction"},[_c('span',[_vm._v(_vm._s(item))])])])}),0),_c('div',{staticClass:"content"},[(_vm.type == 3)?_c('div',{staticClass:"tree-content",class:{ zhixi: _vm.type },style:({
            transform: ("translateX(" + (_vm.type === 3 ? ((_vm.grid[0].offset * 99.8) + "%") : ("calc(" + (_vm.grid[0].offset * 99.8) + "% - " + (_vm.grid[0].offset * 65) + "px)")) + ") rotateY(0deg)"),
          })},_vm._l((_vm.grid[0].list),function(item,index){return _c('sushi-diaoxian-item-zhixi',{key:item.id,style:({
              height: "calc(100% / 7)",
            }),attrs:{"list":item.list,"fatherIndex":index === 0
                ? -1
                : _vm.getFatherPosition(_vm.grid[0].list[index - 1].list),"isFirst":true}})}),1):_c('div',{staticClass:"tree-content",style:({
            transform: ("translateX(" + (_vm.type === 3 ? ((_vm.grid[0].offset * 100) + "%") : ("calc(" + (_vm.grid[0].offset * 100) + "% - " + (_vm.grid[0].offset * 66.5) + "px)")) + ") rotateY(0deg)"),
          })},_vm._l((_vm.grid[0].tree),function(item,index){return _c('sushi-diaoxian-item',{key:index,attrs:{"item":item,"isFirst":true,"isReverse":_vm.grid[0].isReverse}})}),1)])]:_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }