<template>
  <!-- 附近的人 -->
  <div class="Baidu">
    <div class="search">
      <input type="text" placeholder="输入地址" v-model="address" id="suggestId">
      <button @click="search">查询</button>
    </div>
    <div id="map"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      msg: '', // 提示内容
      showAlert: false, // 提示显示
      ifload: false, // loading
      address: '', // 地址
      map: '',
      myValue: ''
    }
  },
  mounted () {
    alert('请在地图上点击以选择你所在的位置')
    // 百度地图API功能
    this.map = new window.BMap.Map("map"); // 创建Map实例
    this.map.centerAndZoom(new window.BMap.Point(this.$route.query.lng,this.$route.query.lat), 10); // 初始化地图,设置中心点坐标和地图级别
    //添加地图类型控件
    this.map.addControl(new window.BMap.MapTypeControl({
      mapTypes: [
        window.BMAP_NORMAL_MAP,
        window.BMAP_HYBRID_MAP,
      ],
    }));
    // 比例尺控件
    this.map.addControl(new window.BMap.ScaleControl({
      anchor: window.BMAP_ANCHOR_BOTTOM_RIGHT, //控件的停靠位置
      offset: new window.BMap.Size(60,10), //控件的水平偏移值
    }));
    // 缩放控件
    this.map.addControl(new window.BMap.NavigationControl({
      offset: new window.BMap.Size(10,10), //控件的水平偏移值
    }))
    this.map.setCurrentCity("长沙"); //设置地图显示的城市 此项是必须设置的
    this.map.enableScrollWheelZoom(true); //开启鼠标滚轮缩放
    this.map.addEventListener('click', (e)=> {
      localStorage.setItem('lng',e.point.lng);
      localStorage.setItem('lat',e.point.lat);
      this.$router.push({name: 'Fujin'})
    });
    var ac = new window.BMap.Autocomplete( //建立一个自动完成的对象
      {
        "input" : "suggestId",
        "location" : window.map,
      }
    );

    ac.addEventListener("onconfirm", (e)=> {    //鼠标点击下拉列表后的事件
      var _value = e.item.value;
      this.myValue = _value.province +  _value.city +  _value.district +  _value.street +  _value.business;
      this.setPlace();
      this.address = this.myValue;
      this.search();
    });
  },
  methods: {
    setPlace(){
      this.map.clearOverlays();    //清除地图上所有覆盖物
      function myFun(){
        var pp = local.getResults().getPoi(0).point;    //获取第一个智能搜索的结果
        this.map.centerAndZoom(pp, 18);
        this.map.addOverlay(new window.BMap.Marker(pp));    //添加标注
      }
      var local = new window.BMap.LocalSearch(window.map, { //智能搜索
        onSearchComplete: myFun
      });
      local.search(this.myValue);
    },
    // 查询
    search () {
      if(this.address != "") {
        console.log(this.address);
        this.map.clearOverlays();
        var localSearch = new window.BMap.LocalSearch(this.map);
        localSearch.enableAutoViewport(); //允许自动调节窗体大小
        localSearch.setSearchCompleteCallback((searchResult) => {
          var poi = searchResult.getPoi(0);
          var marker = new window.BMap.Marker(new window.BMap.Point(poi.point.lng, poi.point.lat));  // 创建标注，为要查询的地址对应的经纬度
          this.map.addOverlay(marker);
          var points = [poi.point];
          var view = this.map.getViewport(eval(points));
          var mapZoom = view.zoom;
          var centerPoint = view.center;
          this.map.centerAndZoom(centerPoint, mapZoom);
        });
        localSearch.search(this.address);
      }
    }
  }
}
</script>

<style lang='scss' scoped>
.Baidu {
  .search {
    display: flex;
    align-items: center;
    position: fixed;
    left: 10px;
    top: 0;
    z-index: 1;
    input {
      width: 150px;
      height: 20px;
      text-align: center;
      outline: none;
    }
    button {
      height: 25px;
      margin-left: 10px;
    }
  }
  .select {
    display: flex;
    flex-direction: column;
    position: fixed;
    left: 10px;
    top: 35px;
    width: 158px;
    height: 145px;
    overflow: auto;
    background: white;
    z-index: 1;
    span {
      width: 97%;
      padding: 5px 0;
      padding-left: 5px;
      border-top: 1px solid #eee;
      font-size: 14px;
    }
  }
  #map {
    width: 100%;
    height: 100vh;
  }
}
</style>