<template>
  <div class="cover page">
    <img src="@/assets/images/cover.png" alt="" />
    <div class="title-area" @click="handleOpenEdit">
      <div class="title-border">
        <span class="title" ref="title">{{ jpmc || "" }}</span>
      </div>
    </div>
    <div class="info-area" @click="handleOpenEdit">
      <div class="block">
        <div class="top">日期</div>
        <div class="bottom">{{ xpsj || "" }}</div>
      </div>
      <div class="block">
        <div class="top">主编</div>
        <div class="bottom">{{ zhubian || "" }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Cover",
  props: {
    jpmc: {
      type: String,
      default: "点击编辑",
    },
    xpsj: {
      type: String,
      default: "点击编辑",
    },
    zhubian: {
      type: String,
      default: "点击编辑",
    },
  },
  // mounted() {
  //   this.$nextTick(() => {
  //     this.autoModifyFontSize([this.$refs.title]);
  //   });
  // },
  watch: {
    jpmc: {
      immediate: true,
      handler() {
        this.$nextTick(() => {
          this.autoModifyFontSize([this.$refs.title]);
        });
      },
    },
  },
  methods: {
    handleOpenEdit() {
      this.$emit("edit-event", {
        type: "cover",
      });
    },
    autoModifyFontSize(elList) {
      elList.forEach((el) => {
        const textHeight = el.offsetHeight;
        const parentHeight = el.parentNode.offsetHeight;
        if (textHeight > parentHeight) {
          el.style.fontSize = `${(parentHeight / textHeight) * 9}vw`;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.cover {
  height: 100%;
  width: 100%;
  position: relative;
  letter-spacing: 5px;

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  .title-area {
    position: absolute;
    top: 55px;
    right: 53px;
    width: 200px;
    height: 467px;
    background: #ffffff;
    border: 7px solid #000000;
    padding: 13px;

    .title-border {
      width: 160px;
      height: 427px;
      background: #ffffff;
      border: 3px solid #000000;
      border-radius: 7px;
      display: flex;
      justify-content: center;
      align-items: center;

      .title {
        font-size: 73px;
        font-weight: 600;
        color: #333333;
        writing-mode: vertical-lr;
        overflow: hidden;
        // text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }

  .info-area {
    position: absolute;
    bottom: 61px;
    left: 94px;
    width: 160px;
    height: 533px;
    background: #ffffff;
    border: 7px solid #000000;
    border-radius: 7px;
    display: flex;

    .block {
      height: 100%;
      width: 50%;

      &:first-child {
        border-right: 1px solid #000000;
      }

      .top {
        height: 127px;
        border-bottom: 1px solid #000000;
      }

      .bottom {
        height: calc(100% - 127px);
      }

      .top,
      .bottom {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        writing-mode: vertical-lr;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
</style>