import 'uni-js-bridge'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { Toast } from 'vant'
import axios from 'axios'
import echarts from "echarts";
import VueDND from "awe-dnd";
import { post, postXForm } from './assets/js/common'
import { get } from './assets/js/common'
import config from '@/config'
import '@/utils/generateTheme'
import 'vant/lib/index.css';
import 'url-search-params-polyfill';

Vue.config.productionTip = false

export const newAxios = axios.create()

Vue.prototype.$postXForm = postXForm
Vue.prototype.$post = post
Vue.prototype.$get = get
Vue.prototype.$axios = newAxios
Vue.prototype.$config = config
Vue.prototype.$echarts = echarts;
Vue.use(VueDND);
// 存token
newAxios.interceptors.request.use(
  (config) => {
    console.log(router.history.current.query.token)
    let token = router.history.current.query.token || localStorage.getItem('token')
    if (token) {
      config.headers.common['token'] = token
      config.params = {
        token,
        ...config.params,
      }
    }
    return config
  },
  (error) => {
    console.log(error)
  }
)

newAxios.interceptors.response.use(
  (response) => {
    switch (response.data.code) {
      case 1:
        Toast.clear()
        return response
      default:
        Toast.fail(response.data.msg || '请求错误！')
        return Promise.reject(response)
    }
  },
  (error) => {
    Toast.fail(error.message || '请求错误！')
    return Promise.reject(error) // 返回接口返回的错误信息
  }
)

document.title = config.projectName

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
