import Vue from 'vue'
import VueRouter from 'vue-router'
// import Book from '../views/Book.vue'
import Book from '../views/BookNew.vue'
import BookPc from '../views/BookPc.vue'
import BookPcJiapu from '../views/BookPcJiapu.vue'
import BookPcCalalog from '../views/BookPcCalalog.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Book,
  },
  {
    path: '/book-index',
    name: 'BookIndex',
    component: () => import('@/views/BookIndex.vue'),
  },
  {
    path: '/book-scroll',
    name: 'BookScroll',
    component: () => import('@/views/BookScroll.vue'),
  },
  {
    path: '/book-pc',
    name: 'BookPc',
    component: BookPc,
  },
  {
    path: '/book-pc-jiapu',
    name: 'BookPcJiapu',
    component: BookPcJiapu,
  },
  {
    path: '/book-pc-catalog',
    name: 'BookPcCatalog',
    component: BookPcCalalog,
  },
  {
    path: '/preview-data',
    name: 'PreviewData',
    component: () => import('@/views/Preview.vue'),
  },
  {
    path: '/game',
    name: 'Game',
    component: () => import('@/views/Game'),
  },
  {
    path: '/dynamic', // 动态
    name: 'dynamic',
    component: () => import('@/views/Dynamic')
  },
  {
    path: '/dynamic_detail', // 动态详情
    name: 'dynamic_detail',
    component: () => import('@/views/Dynamic_detail')
  },
  {
    path: '/comment_detail', // 动态详情
    name: 'comment_detail',
    component: () => import('@/views/comment_detail')
  },
  {
    path: '/release', // 动态发布
    name: 'release',
    component: () => import('@/views/release')
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('@/views/Register'),
  },
  {
    path: '/download-app',
    name: 'DownloadApp',
    component: () => import('@/views/DownloadApp'),
  },
  {
    path: '/user-agreement',
    name: 'UserAgreement',
    component: () => import('@/views/UserAgreement'),
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: () => import('@/views/PrivacyPolicy'),
  },
  {
    path: '/signin', // 签到
    name: 'Signin',
    component: () => import('@/views/study/Sign in'),
    meta: {
      isLeft: true
    }
  },
  {
    path: '/study_buy', // 每日一句购买
    name: 'study_buy',
    component: () => import('@/views/study/study_buy'),
    meta: {
      isLeft: true
    }
  },
  {
    path: '/study', // 今日学习
    name: 'study',
    component: () => import('@/views/study/study'),
    meta: {
      isLeft: true
    }
  },
  {
    path: '/map', // 地图
    name: 'Map',
    component: () => import('@/views/Map'),
    meta: {
      isLeft: true
    }
  },
  {
    path: '/fujin', // 附近的人
    name: 'Fujin',
    component: () => import('@/views/Fujin'),
    meta: {
      isLeft: true
    }
  },
  {
    path: '/baidu', // 百度地图
    name: 'Baidu',
    component: () => require('@/views/Baidu'),
    meta: {
      isLeft: true
    }
  },
  {
    path: '*',
    redirect: '/',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
