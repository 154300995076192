import request from '@/utils/request.js'

export default {
  treeCepu(params) {
    return request({
      method: 'POST',
      url: '/tree_spectrum/cepulists',
      params,
    })
  },
  // 吊线图（家庭谱数据）
  treeSpectrumLists(params) {
    return request({
      method: 'POST',
      url: '/tree_spectrum/lists',
      params,
    })
  },

  // 全部谱
  treeSpectrumListsAll(params) {
    return request({
      method: 'POST',
      url: '/tree_spectrum/searchTreeSpectrumAll',
      params,
    })
  },
  // 基础数据接口
  jichuData(data) {
    return request({
      method: 'POST',
      url: '/tree_spectrum/getJichuData',
      data,
    })
  },
  // 家谱人员详情接口
  treeSpectrumInfo(data) {
    return request({
      method: 'POST',
      url: '/tree_spectrum/info',
      data,
    })
  },
  // 添加家谱人员
  treeSpectrumAdd(data) {
    return request({
      method: 'POST',
      url: '/tree_spectrum/add',
      data,
    })
  },
  // 编辑家谱人员
  treeSpectrumEdit(data) {
    return request({
      method: 'POST',
      url: '/tree_spectrum/edit',
      data,
    })
  },
  // 删除家谱人员
  treeSpectrumDel(data) {
    return request({
      method: 'POST',
      url: '/tree_spectrum/del',
      data,
    })
  },
  // 用户搜索
  searchUser(data) {
    return request({
      method: 'POST',
      url: '/tree_spectrum/userSearch',
      data,
    })
  },

  wufuData(data) {
    return request({
      method: 'POST',
      url: '/tree_spectrum/treeSpectrumFive',
      data,
    })
  },

  // 册谱数据
  getCepuData(data) {
    return request({
      method: 'POST',
      url: '/Genealogy/getCategory',
      data,
    })
  },

  // 册谱数据
  getPcCepuData(data) {
    return request({
      method: 'POST',
      url: '/Book_Spectrum/bookSpectrumContentList',
      data,
    })
  },

  // 修改册谱数据
  editCepuData(data) {
    return request({
      method: 'POST',
      url: '/Genealogy/editCategory',
      data,
    })
  },

  getPudata(data) {
    return request({
      method: 'POST',
      url: '/Genealogy/info',
      data,
    })
  },

  // 用户注册接口
  loginRegister(data) {
    return request({
      method: 'POST',
      url: '/login/register',
      data,
    })
  },

  // 获取验证码
  smsSend(data) {
    return request({
      method: 'POST',
      url: '/sms/send',
      data,
    })
  },

  // 获取APP版本号
  noLoginVersion(data) {
    return request({
      url: '/Nologin/version',
      data,
    })
  },

  // 兄弟亲数据
  xiongdiqinData(data) {
    return request({
      url: '/tree_spectrum/cepulists3',
      data,
      method: 'POST',
    })
  },

  // 一家亲数据
  yijiaqinData(data) {
    return request({
      url: '/tree_spectrum/cepulists4',
      data,
      method: 'POST',
    })
  },

  // 直系数据
  zhixiData(data) {
    return request({
      url: '/tree_spectrum/cepulists2',
      data,
      method: 'POST',
    })
  },

  // 分页测谱数据
  scrollListData(data) {
    return request({
      url: '/tree_spectrum/cepulists7',
      data,
      method: 'POST',
    })
  },

  // 预览谱图文
  previewData(data) {
    return request({
      url: '/Book_Spectrum/getCategory3',
      data,
      method: 'POST',
    })
  }
}
