<template>
  <div class="album page" :class="`album-${arrangement}`">
    <div class="image" v-for="(item, index) in computedImages" :key="index" @click="handleOpenEdit(item, index)">
      <img v-if="item.src" :src="item.src" />
      <div v-if="item.desc" class="desc">{{ item.desc }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Album",
  props: {
    title: {
      type: String,
      default: "",
    },
    arrangement: {
      type: Number,
      default: 4,
    },
    images: {
      type: Object,
      default: () => ({}),
    },
    index: {
      type: Number
    },
    originIndex: {
      type: Number
    }
  },
  computed: {
    computedImages() {
      const imgs = [];
      for (let i = 0; i < this.arrangement; i++) {
        const img = this.images[i]; // 找到position等于位置的元素
        imgs.push(img || {});
      }
      return imgs;
    },
  },
  methods: {
    handleOpenEdit(item, index) {
      this.$emit("edit-event", {
        type: "album",
        data: {
          item,
          pageIndex: this.originIndex,
          itemIndex: index
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.album {
  height: 100%;
  width: 100%;
  position: relative;
  background-color: #fff;

  &.album-4 {
    display: flex;
    flex-flow: row wrap;
    align-content: flex-start;
    // height: calc(100% - 120px);
    // margin: 40px;

    .image {
      height: calc((100% - 120px) / 2);
      width: calc((100% - 120px) / 2);
      background-color: #fff;
      border-radius: 10px;
      overflow: hidden;
      position: relative;
      flex: none;
      margin-left: 40px;
      margin-top: 40px;
      // background-color: #ddd;
      background-color: rgba(0, 0, 0, 0.03);

      &:active {
        border: 1px solid green;
      }

      img {
        height: 100%;
        width: 100%;
        object-fit: contain;
      }

      .desc {
        position: absolute;
        bottom: 0;
        left: 0;
        line-height: 1.5;
        padding: 6px 10px;
        font-size: 24px;
        color: #fff;
        background-color: rgba(0, 0, 0, 0.3);
        border-radius: 10px;
        max-width: 100%;
      }
    }
  }
}
</style>