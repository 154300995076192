<template>
  <div id="book" ref="book" class="book is-print" :key="componentKey">
    <component
      class="book-page"
      v-for="(item, index) in menuArr.menuArr"
      :key="index"
      :is="item.type"
      :index="index"
      :originIndex="item.originIndex"
      :pageIndex="pageIndex"
      :catalogArr="menuArr.catalogArr"
      v-bind="item.data"
      @edit-event="handleEditEvent"
    />
  </div>
</template>

<script>
import "@/utils/turn";
import apis from "@/apis/index";
import Cover from "@/components/BookPc/Cover.vue";
import Album from "@/components/BookPc/Album.vue";
import RichText from "@/components/BookPc/Text.vue";
import Sushi from "@/components/BookPc/Sushi.vue";
import Oushi from "@/components/BookPc/Oushi.vue";
import OushiDiaoxian from "@/components/BookPc/OushiDiaoxian.vue";
import SushiDiaoxian from "@/components/BookPc/SushiDiaoxian.vue";
import IndexTitle from "@/components/BookPc/Title.vue";
import Catalog from "@/components/BookPc/Catalog.vue";
import numberToChinese from "number-to-chinese-words";
import html2canvas from "html2canvas";
import axios from "axios";
import { jsPDF } from "jspdf";
import { chunk, cloneDeep } from "lodash";

const MOBILE_FONT_LIMIT = {
  OUSHI: 70,
  SUSHI: 75,
  TEXT: 600,
};

const PRINT_FONT_LIMIT = {
  OUSHI: 70,
  SUSHI: 75,
  TEXT: 580,
};

const TYPES = {
  ALBUM: "album", // 相册
  TEXT: "rich-text", // 文本
  COVER: "cover", // 封面
  CATALOG: "catalog", // 目录
  IDNEX: "index", // 检索表
  SUSHI: "sushi", // 苏式
  OUSHI: "oushi", // 欧式
  SUSHI_DIAOXIAN: "sushi-diaoxian", // 苏式吊线
  OUSHI_DIAOXIAN: "oushi-diaoxian", // 欧式吊线
  INDEX_TITLE: "index-title", // 标题
};

const PU_TYPE = {
  JIATING: 1,
  ZHIXI: 2,
};

export default {
  name: "BookNew",
  components: {
    Cover,
    Album,
    Sushi,
    SushiDiaoxian,
    Oushi,
    OushiDiaoxian,
    IndexTitle,
    Catalog,
    RichText,
  },
  data() {
    return {
      treeId: undefined, // 中心人id
      creatId: undefined, // 谱id
      type: PU_TYPE.JIATING,
      isPrint: "1",
      isAll: 0,
      pageIndex: 1,
      yangshi: "sushi",
      turn: null,
      inited: false,
      componentKey: 1,
      coverData: {
        type: TYPES.COVER,
        title: "封面",
        size: 1,
        data: {
          jpmc: "",
          zhubian: "",
          xpsj: "",
        },
      }, // 封面数据
      catalogData: {
        type: TYPES.CATALOG,
        title: "目录",
        size: 1,
        data: {},
      }, // 目录数据
      gridData: [],
      gridData1: [],
      gridDataSushiDiaoxian: [],
      gridDataOushiDiaoxian: [],
      menu: [
        // {
        //   type: TYPES.ALBUM,
        //   data: {
        //     title: "家族照",
        //   },
        //   pages: [
        //     {
        //       data: {
        //         arrangement: 4, // 后期可以 124
        //         images: {},
        //       },
        //     },
        //   ],
        // },
        // {
        //   type: TYPES.TEXT,
        //   data: {
        //     title: "家族照",
        //     content: "家族照",
        //   },
        // },
        // {
        //   type: TYPES.SUSHI,
        //   data: {
        //     title: "世系表",
        //   },
        //   pages: [],
        // },
        {
          type: TYPES.OUSHI,
          data: {
            title: "世系表",
          },
          pages: [],
        },
        // {
        //   type: TYPES.SUSHI,
        //   data: {
        //     title: "",
        //     arrangement: 4, // 后期可以 124
        //     images: {},
        //   },
        // },
      ],
      book: {
        title: "",
      },
      fontLimit: this.$route.query.isPrint
        ? PRINT_FONT_LIMIT
        : MOBILE_FONT_LIMIT,
    };
  },
  computed: {
    menuArr() {
      const catalogArr = [this.coverData, this.catalogData];
      const menuPage = this.menu.reduce((prev, curr, originIndex) => {
        const catalogData = {
          type: curr.type,
          title: curr.data.title || curr.data.customTitle,
          size: 0,
        };

        if (curr.data.title) {
          // 先加一个titlepage
          if (curr.type !== TYPES.TEXT) {
            prev.push({
              type: TYPES.INDEX_TITLE,
              data: {
                title: curr.data.title,
              },
            });
          } else {
            const textLength = curr?.data?.content?.length || 0;
            if (textLength < this.fontLimit.TEXT) {
              prev.push({
                ...curr,
                data: { ...curr.data, originContent: curr.data.content },
                originIndex,
              });
            } else {
              let offset = 0;
              while (offset < textLength) {
                const newItem = cloneDeep({
                  ...curr,
                  data: { ...curr.data, originContent: curr.data.content },
                  originIndex,
                });
                newItem.data.content = curr?.data?.content.substr(
                  offset,
                  this.fontLimit.TEXT
                );
                prev.push(newItem);
                offset += this.fontLimit.TEXT;
              }
            }
          }

          catalogData.size += 1;
        }

        if (curr.pages) {
          catalogData.size += curr.pages.length;
          prev = prev.concat(
            curr.pages.map((item) => {
              return {
                ...item,
                originIndex,
                type: curr.type,
              };
            })
          );
        }

        // if (curr.type === TYPES.SUSHI) {
        //   catalogData.size += this.gridData.length;
        //   prev = prev.concat(
        //     this.gridData.map((item) => {
        //       return {
        //         data: {
        //           grid: item,
        //         },
        //         type: TYPES.SUSHI,
        //       };
        //     })
        //   );
        // }

        if (curr.type === TYPES.OUSHI || curr.type === TYPES.SUSHI) {
          if (this.yangshi === "sushi") {
            let gridDataSushiDiaoxian = this.gridDataSushiDiaoxian;

            if (this.type != 1) {
              // 如果是直系谱 过滤掉多添加的分页
              gridDataSushiDiaoxian = this.gridDataSushiDiaoxian.filter(
                (item) => item.offset === 0
              );
            }
            // 添加苏式吊线
            catalogData.size += gridDataSushiDiaoxian.length;
            prev = prev.concat(
              gridDataSushiDiaoxian.map((item) => {
                return {
                  data: {
                    grid: item,
                  },
                  type: TYPES.SUSHI_DIAOXIAN,
                };
              })
            );

            // 添加苏式册谱
            catalogData.size += this.gridData.length;
            prev = prev.concat(
              this.gridData.map((item) => {
                return {
                  data: {
                    grid: item,
                  },
                  type: TYPES.SUSHI,
                };
              })
            );
          } else {
            // 添加欧式吊线
            catalogData.size += this.gridDataOushiDiaoxian.length;
            prev = prev.concat(
              this.gridDataOushiDiaoxian.map((item) => {
                return {
                  data: {
                    grid: item,
                  },
                  type: TYPES.OUSHI_DIAOXIAN,
                };
              })
            );
            // 添加欧式册谱
            catalogData.size += this.gridData1.length;
            prev = prev.concat(
              this.gridData1.map((item, index) => {
                return {
                  data: {
                    grid: item,
                    factions: this.gridData1faction[index],
                  },
                  type: TYPES.OUSHI,
                };
              })
            );
          }
        }
        catalogArr.push(catalogData);
        return prev;
      }, []);
      return {
        menuArr: [this.coverData, this.catalogData, ...menuPage],
        catalogArr,
      };
    },
  },
  mounted() {
    this.treeId = this.$route.query.treeId; // 人id
    this.creatId = this.$route.query.creatId; // 谱id
    this.type = this.$route.query.type; // 家庭/直系
    this.isAll = this.$route.query.isAll; // 是否全部
    this.yangshi = this.$route.query.yangshi || "sushi"; // 欧式 苏式
    this.isPrint = this.$route.query.isPrint || 0;
    this.pageIndex = this.$route.query.pageIndex || 1;
    this.getPuData();
    this.getDiaoxianData();
    this.getMenuData();
    this.revicerPageSwitch();

    // setTimeout(() => {
    //   this.generatePdf()
    // }, 3000);
  },
  watch: {
    menuArr: {
      handler() {
        // this.initTurn();
        this.handleUpdatePageSize();
      },
      immediate: true,
    },
  },
  methods: {
    generatePdf() {
      html2canvas(this.$refs.book, {
        scale: 2,
        // background: "#0B1A48",
      }).then((canvas) => {
        console.log(canvas);
        const contentWidth = canvas.width;
        const contentHeight = canvas.height;
        // 一页pdf显示html页面生成的canvas高度;
        const pageHeight = (contentWidth / 592.28) * 841.89;
        // 未生成pdf的html页面高度
        let leftHeight = contentHeight;
        // pdf页面偏移
        let position = 0;
        // a4纸的尺寸[595.28,841.89]，html页面生成的canvas在pdf中图片的宽高
        const imgWidth = 595.28;
        const imgHeight = (592.28 / contentWidth) * contentHeight;
        const pageData = canvas.toDataURL("image/jpeg", 1.0);
        // eslint-disable-next-line
        const pdf = new jsPDF("", "pt", "a4");
        // 有两个高度需要区分，一个是html页面的实际高度，和生成pdf的页面高度(841.89)
        // 当内容未超过pdf一页显示的范围，无需分页
        if (leftHeight < pageHeight) {
          pdf.addImage(pageData, "JPEG", 0, 0, imgWidth, imgHeight);
        } else {
          while (leftHeight > 0) {
            pdf.addImage(pageData, "JPEG", 0, position, imgWidth, imgHeight);
            leftHeight -= pageHeight;
            position -= 841.89;
            // 避免添加空白页
            if (leftHeight > 0) {
              pdf.addPage();
            }
          }
        } // 导出pdf文件命名
        axios.post("http://leheapi.ylliushi.com/api/tree_spectrum/pdf", {
          fileName: btoa(pdf.output()),
        });
        pdf.save("report_pdf_" + new Date().getTime() + ".pdf");
      });
    },
    initTurn() {
      // if (this.inited) {
      //   $("#book").turn("destroy");
      //   console.log('destroy turn')
      // }
      // console.log(this.isPrint, this.isPrint === '1')
      if (this.isPrint === "1") return;
      this.componentKey += 1;
      this.$nextTick(() => {
        $("#book").turn({
          autoCenter: true,
          display: "single",
          page: this.pageIndex,
          when: {
            turning: (e, page, pageObj) => {
              this.pageIndex = Number(page);
              this.handlePageUpdate(Number(page));
            },
          },
        });
      });
      this.inited = true;
    },
    getPuData() {
      if (!this.creatId) return;
      apis
        .getPudata({
          creatId: this.creatId,
        })
        .then((data) => {
          this.coverData.data = data;
        });
    },
    getMenuData() {
      apis
        .getCepuData({
          genealogyId: this.creatId,
        })
        .then((data) => {
          try {
            this.menu = JSON.parse(data); //
            console.log(this.menu);
          } catch (error) {
            console.error(error);
          }
        });
    },
    async getDiaoxianData() {
      let res = {};
      if (this.isAll === "1") {
        res = await apis.treeSpectrumListsAll({
          treeid: this.treeId,
          creatId: this.creatId,
          limit: 10000,
        });
      } else {
        res = await apis.treeCepu({
          treeid: this.treeId,
          creatId: this.creatId,
          dataType: this.type,
          limit: 10000,
        });
      }
      const { data, count } = res;
      const pai = {};
      const paiNameStr = [];
      let treeDataBeforeGrandfather = [];
      const gridData1 = [];
      const gridData1faction = [];
      // const data = cloneDeep(res);
      const sortedData = Object.values(data).sort((a, b) => {
        return a.factions - b.factions;
      });
      for (const item of sortedData) {
        // const item = data[id]; // 每一项
        if (!pai[item.factions]) {
          const paiName = `${numberToChinese.toWords(item.factions)}派`;
          pai[item.factions] = {
            name: paiName, // 派名number-to-chinese-words
            id: item.factions, // 派id
            list: [], // 派列表
          };
          paiNameStr[item.factions - 1] = paiName;
          treeDataBeforeGrandfather[item.factions - 1] = [];
        }
        item["factionsName"] = paiNameStr[item.factions - 1];
        if (item.father_id && data[item.father_id]) {
          item.fatherName = data[item.father_id]?.name;
          item.fatherRankId = data[item.father_id]?.rank_id;
          // if (!data[item.father_id]) {
          //   console.log(item, item.father_id, data);
          // }

          if (data[item.father_id].child) {
            data[item.father_id].child.push(item);
            data[item.father_id].child.forEach((childItem) => {
              childItem.isOnly = false;
            });
          } else {
            data[item.father_id].child = [item];
            item.isOnly = true;
          }
        }
        pai[item.factions].list.push(item); // 根据派分类
        treeDataBeforeGrandfather[item.factions - 1].splice(
          item.rank_id > 0 ? item.rank_id - 1 : 0,
          0,
          item
        );

        // 欧式册谱数据
        const descLength = item.des?.length || 0;
        if (descLength < this.fontLimit.OUSHI) {
          // 介绍少于40
          gridData1.push(item);
          gridData1faction.push(item.factions);
        } else {
          let offset = 0;
          while (offset < descLength) {
            const newItem = offset === 0 ? { ...item } : {};
            newItem.des = item.des.substr(offset, this.fontLimit.OUSHI);
            gridData1.push(newItem);
            gridData1faction.push(item.factions);
            offset += this.fontLimit.OUSHI;
          }
        }
        // console.log(descLength)
        // prev = prev.concat(item);
        if (item.spouseArrs?.length) {
          // 如果有配偶
          gridData1.push(...item.spouseArrs);
          gridData1faction.push(
            ...item.spouseArrs.map((item) => item.factions)
          );
        }
        // 欧式谱end
        // gridData2.push(...item.spouseArrs)
      }

      treeDataBeforeGrandfather = treeDataBeforeGrandfather.filter(Boolean);

      // console.log(treeDataBeforeGrandfather)

      const gridData = treeDataBeforeGrandfather.reduce((prev, curr) => {
        curr
          .sort((a, b) => {
            if (a.fatherRankId !== b.fatherRankId) {
              return a.fatherRankId - b.fatherRankId;
            }
            if (a.sex !== b.sex) {
              return b.sex - a.sex;
            } else {
              return (
                (a.rank_id || Number.POSITIVE_INFINITY) -
                (b.rank_id || Number.POSITIVE_INFINITY)
              );
            }
          })
          .forEach((item) => {
            const descLength = item.des?.length || 0;
            if (descLength < this.fontLimit.SUSHI) {
              // 介绍少于40
              prev = prev.concat(item);
            } else {
              let offset = 0;
              while (offset < descLength) {
                const newItem = offset === 0 ? { ...item } : {};
                newItem.des = item.des.substr(offset, this.fontLimit.SUSHI);
                prev.push(newItem);
                offset += this.fontLimit.SUSHI;
              }
            }
            // console.log(descLength)
            // prev = prev.concat(item);
            if (item.spouseArrs?.length) {
              // 如果有配偶
              prev = prev.concat(item.spouseArrs);
            }
          });

        return prev;
      }, []);

      // const gridData1 = treeDataBeforeGrandfather.reduce((prev, curr) => {
      //   console.log(curr)
      //   curr.forEach((item) => {
      //     const descLength = item.des?.length || 0;
      //     if (descLength < 85) {
      //       // 介绍少于40
      //       prev = prev.concat(item);
      //     } else {
      //       let offset = 0;
      //       while (offset < descLength) {
      //         const newItem = offset === 0 ? { ...item } : {};
      //         newItem.des = item.des.substr(offset, 85);
      //         prev.push(newItem);
      //         offset += 85;
      //       }
      //     }
      //     // console.log(descLength)
      //     // prev = prev.concat(item);
      //     if (item.spouseArrs?.length) {
      //       // 如果有配偶
      //       prev = prev.concat(item.spouseArrs);
      //     }
      //   });

      //   return prev;
      // }, []);

      // const gridDataLost = gridData.length % 10
      // const gridData1Lost = gridData1.length % 8
      // // const gridData1factionLost = gridData % 8

      // gridData.push(...new Array(gridDataLost).fill({}));
      // gridData1.push(...new Array(gridData1Lost).fill({}));
      // gridData1faction.push(...new Array(gridData1factionLost).fill({}));

      this.gridData = chunk(gridData, 10);
      this.gridData1 = chunk(gridData1, 8);
      this.gridData1faction = chunk(gridData1faction, 8);

      const chunkedSushiDiaoxianData = cloneDeep(chunk(Object.values(pai), 7))
        // .slice(0, 2)
        .reduce((prev, curr) => {
          const factions = curr.map((item) => item.name);
          const offsetMap = {};
          let offset = 0;
          curr.forEach((tcurr) => {
            tcurr.list.forEach((item, index) => {
              if (item.father_id && offsetMap[String(item.father_id)]) {
                offsetMap[String(item.id)] =
                  parseInt(offsetMap[String(item.father_id)]) + index;
              } else {
                offsetMap[String(item.id)] = index;
              }

              // console.log(item.name, offsetMap[String(item.id)])

              // console.log(JSON.stringify(offsetMap));

              // if (index !== 0 && tcurr.list[index - 1] && tcurr.list[index - 1].child) {
              //   tcurr.list[index - 1].child.forEach((childItem) => {
              //     offsetMap[item.id] += offsetMap[childItem.id]
              //   });
              // }

              // offset = offsetMap[String(item.id)];
              // item.name = item.name + offset;
              // if (offset > 1) {
              //   // tcurr.list.splice(index, 1);
              //   console.log(item.name, offset);
              // } else {
              //   tprev.push(item);
              // }
            });
          });
          const tree = this.arrToTree(
            [...curr].reverse().reduce((tprev, tcurr) => {
              tcurr.list.forEach((item, index) => {
                if (index !== 0 && tcurr.list[index - 1]) {
                  if (tcurr.list[index - 1].child) {
                    let beforeOffset = 0;
                    tcurr.list[index - 1].child.forEach((childItem) => {
                      beforeOffset = Math.max(
                        offsetMap[String(childItem.id)] || 0,
                        beforeOffset
                      );

                      // console.log(beforeOffset, item.name);
                      // offset = offsetMap[item.id];
                      // item.name = item.name + offset;
                    });
                    offsetMap[String(item.id)] = beforeOffset + 1;
                  } else {
                    offsetMap[String(item.id)] =
                      offsetMap[tcurr.list[index - 1].id] + 1;
                  }
                }
                if (item.child) {
                  const childOffset = this.getChildOffset(item, offsetMap);
                  // let childOffset = 0;
                  // item.child.forEach((childItem) => {
                  //   childOffset = Math.max(
                  //     offsetMap[String(childItem.id)] || 0,
                  //     childOffset
                  //   );
                  //   // offset = offsetMap[item.id];
                  //   // item.name = item.name + offset;
                  // });
                  offsetMap[String(item.id)] = childOffset;
                }
                // item.name = item.name + offsetMap[String(item.id)];
                // if (offsetMap[String(item.id)] < 10) {
                //   tprev.push(item);
                // }
                offset = Math.max(offsetMap[String(item.id)] || 0, offset);
              });
              tprev.unshift(...tcurr.list);
              return tprev;
            }, [])
          );
          const tempArr = [];
          // offset = offset + 1
          for (let i = 0; i <= Math.floor(offset / 8); i++) {
            tempArr.push({
              list: curr,
              factions,
              tree,
              offset: i,
              // isReverse: i % 2 === 1,
            });
          }
          // while (offset >= 0) {
          //   tempArr.push({
          //     ...curr,
          //     factions,
          //     tree,
          //     offset: Math.floor(offset / 10),
          //   });
          //   offset = offset - 10
          // }

          prev.push(...tempArr);

          return prev;
        }, []);
      console.log(chunkedSushiDiaoxianData);
      this.gridDataSushiDiaoxian = chunkedSushiDiaoxianData;

      const chunkedOushiDiaoxianData = chunk(Object.values(pai), 5)
        // .slice(0, 2)
        .reduce((prev, curr) => {
          const factions = curr.map((item) => item.name);
          const offsetMap = {};
          let offset = 0;
          curr.forEach((tcurr) => {
            tcurr.list.forEach((item, index) => {
              if (item.father_id && offsetMap[String(item.father_id)]) {
                offsetMap[String(item.id)] =
                  parseInt(offsetMap[String(item.father_id)]) + index;
              } else {
                offsetMap[String(item.id)] = index;
              }

              // console.log(item.name, offsetMap[String(item.id)])

              // console.log(JSON.stringify(offsetMap));

              // if (index !== 0 && tcurr.list[index - 1] && tcurr.list[index - 1].child) {
              //   tcurr.list[index - 1].child.forEach((childItem) => {
              //     offsetMap[item.id] += offsetMap[childItem.id]
              //   });
              // }

              // offset = offsetMap[String(item.id)];
              // item.name = item.name + offset;
              // if (offset > 1) {
              //   // tcurr.list.splice(index, 1);
              //   console.log(item.name, offset);
              // } else {
              //   tprev.push(item);
              // }
            });
          });
          const tree = this.arrToTree(
            [...curr].reverse().reduce((tprev, tcurr) => {
              tcurr.list.forEach((item, index) => {
                if (index !== 0 && tcurr.list[index - 1]) {
                  if (tcurr.list[index - 1].child) {
                    let beforeOffset = 0;
                    tcurr.list[index - 1].child.forEach((childItem) => {
                      beforeOffset = Math.max(
                        offsetMap[String(childItem.id)] || 0,
                        beforeOffset
                      );

                      // console.log(beforeOffset, item.name);
                      // offset = offsetMap[item.id];
                      // item.name = item.name + offset;
                    });
                    offsetMap[String(item.id)] = beforeOffset + 1;
                  } else {
                    offsetMap[String(item.id)] =
                      offsetMap[tcurr.list[index - 1].id] + 1;
                  }
                }
                if (item.child) {
                  const childOffset = this.getChildOffset(item, offsetMap);
                  // let childOffset = 0;
                  // item.child.forEach((childItem) => {
                  //   childOffset = Math.max(
                  //     offsetMap[String(childItem.id)] || 0,
                  //     childOffset
                  //   );
                  //   // offset = offsetMap[item.id];
                  //   // item.name = item.name + offset;
                  // });
                  offsetMap[String(item.id)] = childOffset;
                }
                // item.name = item.name + offsetMap[String(item.id)];
                // if (offsetMap[String(item.id)] < 10) {
                //   tprev.push(item);
                // }
                offset = Math.max(offsetMap[String(item.id)] || 0, offset);
              });
              tprev.unshift(...tcurr.list);
              return tprev;
            }, [])
          );
          const tempArr = [];
          // offset = offset + 1
          for (let i = 0; i <= Math.floor(offset / 15); i++) {
            tempArr.push({
              ...curr,
              factions,
              tree,
              offset: i,
              // isReverse: i % 2 === 1,
            });
          }
          // while (offset >= 0) {
          //   tempArr.push({
          //     ...curr,
          //     factions,
          //     tree,
          //     offset: Math.floor(offset / 10),
          //   });
          //   offset = offset - 10
          // }

          prev.push(...tempArr);

          return prev;
        }, []);

      this.gridDataOushiDiaoxian = chunkedOushiDiaoxianData;

      // console.log(this.gridData1);
    },
    getChildOffset(item, offsetMap) {
      let childOffset = offsetMap[String(item.id)] || 0;
      if (item.child) {
        item.child.forEach((childItem) => {
          const offset = this.getChildOffset(childItem, offsetMap);
          childOffset = Math.max(offset, childOffset);
          // offset = offsetMap[item.id];
          // item.name = item.name + offset;
        });
      }
      return childOffset;
    },
    handleEditEvent(data) {
      UniJsBridge.callHandler("webviewMessage", data, (res) => {
        console.log("res", res);
        this.getPuData();
        this.getMenuData();
        this.getDiaoxianData();
      });
      // uni.postMessage({
      //   data: {
      //     type: data.type,
      //   },
      // });
    },
    handleUpdatePageSize() {
      const pageSize = this.menuArr.menuArr.length;
      console.log(pageSize);
      const data = {
        type: "update-page-size",
        pageSize,
      };
      UniJsBridge.callHandler("webviewMessage", data, (res) => {});
    },
    handlePageUpdate(page) {
      const data = {
        type: "page-update",
        page,
      };
      UniJsBridge.callHandler("webviewMessage", data, (res) => {});
    },
    revicerPageSwitch() {
      UniJsBridge.registerHandler("change-page", (data, responseCallback) => {
        console.log("turn page", data.page);
        clearTimeout(this.switchPageTimer);
        this.switchPageTimer = null;
        if (data.page && data.page !== this.page) {
          this.switchPageTimer = setTimeout(() => {
            $("#book").turn("page", data.page);
          }, 200);
        }
        // window.location.reload()
        // setTimeout(() => {
        //   // data: 是客户端给H5的参数
        //   alert(JSON.stringify(data));

        //     // 把参数传递给客户端,如果不执行的话，uni-app是无法执行回调的
        //     responseCallback('我是给uni-app的参数');
        // }, 2000);
      });
    },
    arrToTree(data) {
      let tree = [];
      if (!Array.isArray(data)) {
        return tree;
      }
      // 将数组转换成对象（键值对），将ID作为属性名，原来的数组里的对象作为属性值
      let map = {};
      data.forEach((item) => {
        map[item.id] = item;
      });
      // 通过对象的属性名（ID）来找到父级节点，将存到map里的对象取出来放到父级节点的childere数组中
      data.forEach((item) => {
        let parent = map[item.father_id];
        // if (parent && parent.is_zeng !== 1) {
        //   parent = map[item.mother_id]
        // }

        if (parent) {
          (parent.gridChild || (parent.gridChild = [])).push(item);
          if (parent.gridChild.length === 1) {
            parent.gridChild.forEach((childItem) => {
              childItem.isOnly = true;
            });
          } else {
            parent.gridChild.forEach((childItem) => {
              childItem.isOnly = false;
            });
          }
          parent.gridChild = parent.gridChild.sort((a, b) => {
            if (a.sex !== b.sex) {
              return b.sex - a.sex;
            } else {
              return (
                (a.rank_id || Number.POSITIVE_INFINITY) -
                (b.rank_id || Number.POSITIVE_INFINITY)
              );
            }
          });
        } else if (item.is_zeng === 1) {
          tree.push(item);
        }
      });
      return tree;
    },
  },
};
</script>

<style lang="scss" scoped>
.book {
  width: 750px;
  // height: 1061px;
  // border: 1px solid #ddd;
  &::-webkit-scrollbar {
    display: none;
  }

  .book-page { // a4比例
    width: 750px;
    height: 1053.5px;
  }

  // &.is-print {
  //   width: 100%;
  //   height: 100%;
  //   margin-top: -0.5px;

  //   & > div {
  //     width: 750px;
  //     height: 1063.5px;
  //     // padding: 20px;
  //     border: 2px solid #444;
  //     transform: scale(0.9) translateY(20px);
  //     // position: relative;
  //     // box-sizing: border-box;
  //     // background-color: #ddd;
  //     // border: 1px solid #b5b5b5;
  //     // margin: 40px 0;
  //   }
  // }

  // & > div {
  //   height: 100%;
  //   width: 100%;
  //   // background-color: #ddd;
  // }
}
</style>