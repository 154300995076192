import axios from 'axios'
import router from '@/router/index'
import { Toast } from 'vant'
import env from '@/config/index'

export const BASE_URL = `${env.BASE_URL}/api` // api的base_url

const usp = new URLSearchParams(window.location.search)

console.log(localStorage.getItem('token') || usp.get('token') || '')

// 创建axios实例
const service = axios.create({
  baseURL: BASE_URL, // api的base_url
  timeout: 500000, // 请求超时时间 10s
})

// request 请求发送之前 拦截器
service.interceptors.request.use(
  (config) => {
    Toast.loading({
      message: '加载中...',
      forbidClick: true,
      duration: 0,
    })
    return {
      ...config,
      params: {
        ...config.params,
        token: usp.get('token') || localStorage.getItem('token') || '', // 把 token 加到 url 参数里面
      },
    }
  },
  (error) => {
    // Do something with request error
    console.log(error)
    Promise.reject(error)
  }
)

// request 请求收到后 拦截器设置
service.interceptors.response.use(
  (response) => {
    switch (response.data.code) {
      case 1:
        Toast.clear()
        return response.data.data
      default:
        Toast.fail(response.data.msg || '请求错误！')
        return Promise.reject(response)
    }
  },
  (error) => {
    Toast.fail(error.message || '请求错误！')
    return Promise.reject(error) // 返回接口返回的错误信息
  }
)

export default service
