<template>
  <div class="pu-info">
    <div class="title">
      <span ref="jpmc">{{ puInfo.jpmc }}</span>
    </div>
    <div class="bottom-wrapper">
      <div class="create-date">
        <span ref="xpsj">{{ puInfo.xpsj }}</span>
      </div>
      <div class="shixi">
        <span ref="sxmc">{{ puInfo.sxmc }}</span>
      </div>
      <div class="angle-lt"></div>
      <div class="angle-lb"></div>
      <div class="angle-rt"></div>
      <div class="angle-rb"></div>
    </div>
    <div class="tanghao">
      <span ref="tanghao">{{ puInfo.tanghao }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "PuInfo",
  props: {
    title: {
      type: String,
      default: "",
    },
    puInfo: {
      type: Object,
      default: () => {},
    },
  },
  mounted() {
    this.$nextTick(() => {
      console.log(this.$refs);
      this.autoModifyFontSize(Object.values(this.$refs));
    });
  },
  methods: {
    autoModifyFontSize(elList) {
      elList.forEach((el) => {
        const textWidth = el.offsetWidth;
        const parentWidth = el.parentNode.offsetWidth;

        const textHeight = el.offsetHeight;
        const parentHeight = el.parentNode.offsetHeight;

        if (textWidth > parentWidth) {
          el.style.fontSize = `${(parentWidth / textWidth) * 70}px`;
        }

        if (textHeight > parentHeight) {
          el.style.fontSize = `${(parentHeight / textHeight) * 40}px`;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.pu-info {
  border: 3px solid #000;
  border-top: none;
  border-bottom: none;
  display: flex;
  flex-flow: column nowrap;

  .title {
    font-size: 52px;
    height: 268px;
    display: flex;
    align-items: center;
    justify-content: center;
    // border-bottom: 3px solid #000;
    writing-mode: vertical-rl;
    white-space: nowrap;
    font-weight: bold;
  }

  .bottom-wrapper {
    position: relative;
    flex: 1;
    box-sizing: border-box;
    padding: 60px 0;
    display: flex;
    justify-content: space-between;
    flex-flow: row nowrap;

    .angle-lt {
      position: absolute;
      top: 0;
      left: 0;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 40px 40px 0 0;
      border-color: #000000 transparent transparent transparent;
    }

    .angle-lb {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 40px 0 0 40px;
      border-color: transparent transparent transparent #000000;
    }

    .angle-rt {
      position: absolute;
      top: 0;
      right: 0;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 40px 40px 0;
      border-color: transparent #000000 transparent transparent;
    }

    .angle-rb {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 0 40px 40px;
      border-color: transparent transparent #000000 transparent;
    }
  }

  .create-date,
  .shixi {
    font-size: 22px;
    width: 50%;
    display: flex;
    align-items: center;
    writing-mode: vertical-rl;
    white-space: nowrap;
  }

  .tanghao {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
    font-size: 44px;
    writing-mode: vertical-rl;
    flex: none;
    height: 200px;
    overflow: hidden;
    white-space: nowrap;
    font-weight: bold;
  }
}
</style>