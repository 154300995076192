<template>
  <div class="oushi-diaoxian-item">
    <div
      class="name-wrapper"
      :class="{ 'is-first': isFirst }"
      :style="`--border-width: ${leftWidth}px`"
    >
      <div class="name">
        
        <div
          class="has-father"
          :class="{
            'has-father-left': index !== 0,
          }"
          v-if="item.is_zeng === 1 && item.father_id"
        ></div>
        <div class="bg">
        </div>

        <span>{{ item.is_zeng === 1 ? item.name : item.username }}</span>
          

        <div
          class="has-son"
          v-if="item.is_zeng === 1 && item.gridChild && item.gridChild.length"
        ></div>
      </div>
      <!-- <div class="name" v-for="(spouse, index) in item.spouseArrs" :key="index">
        {{ spouse.is_zeng === 1 ? spouse.name : spouse.username }}
      </div> -->
    </div>
    <div v-if="item.gridChild" class="children">
      <oushi-diaoxian-item
        v-for="(subItem, index) in item.gridChild"
        ref="children"
        :index="index"
        :isLast="index === item.gridChild.length - 1"
        :key="index"
        :item="subItem"
        @update-left-width="updateLeftWidth"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "oushi-diaoxian-item",
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    index: {
      type: Number,
      default: 0,
    },
    isFirst: {
      type: Boolean,
      default: false,
    },
    isLast: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      leftWidth: 67,
      childLeftWidth: [],
      originLeftWidth: 67,
      leftSpouseNum: 0,
    };
  },
  computed: {
    // emptyArr() {
    //   return new Array(10 - this.grid.length).fill(0).map((item, index) => {});
    // },
    childWidth() {
      return this.childLeftWidth.reduce((prev, curr) => prev + curr, 0);
    },
  },
  created() {
    this.leftWidth = this.index === 0 ? 0 : 67;
    this.originLeftWidth = this.index === 0 ? 0 : 67;
  },
  watch: {
    childWidth() {
      this.calcLeftWidth();
      this.$emit("update-left-width", {
        index: this.index,
        width: this.leftWidth + (this.isLast ? this.childWidth : 0),
        // childWidth: this.childWidth,
        // leftSpouseNum: this.leftSpouseArrs.length + this.leftSpouseNum,
      });
    },
  },
  mounted() {
    // this.leftWidth = this.leftWidth + (this.item.sposeArrs ? this.item.sposeArrs.length * 0.2 : 0);
    // const leftWidth = 7 + (this.item.spouseArrs ? this.item.spouseArrs.length * 0.2 : 0);
    // console.log(leftWidth)
    this.calcLeftWidth();

    this.$emit("update-left-width", {
      index: this.index,
      width: this.leftWidth + (this.isLast ? this.childWidth : 0),
      // childWidth: this.childWidth,
      // leftSpouseNum: this.leftSpouseArrs.length + this.leftSpouseNum,
    });
    // console.log(leftBrother && leftBrother.childLeftWidth.reduce((prev, curr) => prev + curr, 0))
  },
  methods: {
    splitText(str) {
      if (!str) return "";
      let strArr = str.split("");
      let offset = 0;
      while (offset < str.length) {
        offset += 2;
        strArr.splice(offset + Math.floor(offset / 2) - 1, 0, "\n");
      }
      return strArr.join("");
    },
    isSameFather(item1, item2) {
      return item1.father_id === item2.father_id;
    },
    handleChildWidthChange({ index, width }) {
      // const leftBrother = this.index === 0 ? null : this.$parent.$refs.children[this.index - 1];
      const nextBrother =
        this.$parent.$refs.children &&
        this.$parent.$refs.children[this.index + 1];
      if (nextBrother) {
        nextBrother.updateLeftWidth({ index: index, width: width });
      }
    },
    updateLeftWidth({ index, width, leftSpouseNum }) {
      // console.log(width)
      // this.childLeftWidth.splice(index, 1, width);
      // this.childLeftWidth[index] = width;

      this.$set(this.childLeftWidth, index, width);
      // this.leftSpouseNum = this.leftSpouseNum + leftSpouseNum
      // const leftWidth = this.childLeftWidth.reduce((prev, curr) => prev + curr, 0);
      // const beforeBrother = this.index === 0 ? null : this.$parent.$refs.children && this.$parent.$refs.children[this.index - 1];
      // console.log(this.childLeftWidth)
      // this.leftWidth = leftWidth + this.originLeftWidth + beforeBrother.leftWidth
      // this.$emit('update-left-width', { index: this.index, width: this.leftWidth })
    },
    getChildWidth(component) {
      let width = 0;

      if (component.childLeftWidth.length > 1) {
        width += component.childLeftWidth.reduce(
          (prev, curr) => prev + curr,
          0
        );
      } else if (component.childLeftWidth.length === 1) {
        width += this.getChildWidth(component.$refs.children[0]);
      }
      // if (component.$refs.children) {
      //   component.$refs.children.forEach((child) => {
      //     width += child.index === 0 ? 0 :this.getChildWidth(child);
      //   });
      // } else {
      //   width += 9;
      // }
      return width;
    },
    calcLeftWidth() {
      const beforeBrother =
        this.index === 0
          ? null
          : this.$parent.$refs.children &&
            this.$parent.$refs.children[this.index - 1];
      const afterBrother =
        this.$parent.$refs.children &&
        this.$parent.$refs.children[this.index + 1];
      let leftBrotherWidth = 0;
      let rightBrotherWidth = 0;
      if (beforeBrother) {
        // beforeBrother.updateLeftWidth({ index: this.index, width: this.leftWidth });
        // console.log(beforeBrother.childLeftWidth.reverse()[0])
        // const lastLength = beforeBrother.childLeftWidth.slice(-1)[0] || 0;

        // this.leftWidth =
        //   this.originLeftWidth + beforeBrother.leftWidth + lastLength - 3.5;
        // leftBrotherWidth = beforeBrother.childLeftWidth
        //   ? beforeBrother.childWidth
        //   : 0;

        // if (
        //   beforeBrother.childLeftWidth.length === 1 &&
        //   beforeBrother.childLeftWidth[0] === 0
        // ) {
        //   leftBrotherWidth += beforeBrother.$refs.children[0].childWidth
        // }

        leftBrotherWidth = this.getChildWidth(beforeBrother);
      }

      // if (afterBrother) {
      //   rightBrotherWidth = this.getChildWidth(afterBrother);
      //   console.log(rightBrotherWidth);
      // }

      // if (!rightBrother) {
      //   rightBrotherWidth = this.childWidth;
      // }

      this.leftWidth = this.index === 0 ? 0 : 66 + leftBrotherWidth;

      if (this.leftWidth > 1125) {
        this.leftWidth = this.leftWidth - 3.25;
      }

      return this.leftWidth;
    },
  },
};
</script>

<style lang="scss" scoped>
.oushi-diaoxian-item {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
}

.name-wrapper {
  width: 90px;
  // min-height: 2em;
  font-size: 32px;
  // margin-right: 47px;
  margin-top: 23px;
  margin-bottom: 23px;
  margin-left: 46px;
  display: flex;
  align-items: flex-start;
  flex-flow: column nowrap;
  flex: none;
  // text-align: center;

  &.is-first {
    margin-left: 0;
  }
}

.name {
  // height: 118px;
  position: relative;
  // font-size: 32px;
  margin-right: 7px;
  flex: none;
  background-color: #fff;
  width: 52px;
  text-align: center;

  span {
    position: relative;
  }

  .bg {
    background-color: #fff;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // display: inline-block;
  }
  // text-align: center;

  &:first-of-type {
    margin-right: 0;
  }

  line-height: 1;
  // width: 18px;
  // height: 41px;
  font-size: 20px;
  // font-family: PingFang SC;
  font-weight: 400;
  color: #ac0404;
  // writing-mode: vertical-rl;
}

.has-father {
  // width: 11px;
  // height: 11px;
  // background: #ac0404;
  // border-radius: 50%;
  position: absolute;
  // top: -20px;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);

  &.has-father-left {
    &::before {
      content: "";
      display: block;
      height: var(--border-width);
      width: 2px;
      background: #ac0404;
      position: absolute;
      right: 90px;
      bottom: 0;
    }
  }

  &::after {
    content: "";
    display: block;
    height: 2px;
    width: 51px;
    background: #ac0404;
    position: absolute;
    right: 16px;
    bottom: 0;
    transform: translateX(-50%);
  }
}

.has-son {
  content: "";
  display: block;
  height: 2px;
  width: 50px;
  background: #ac0404;
  position: absolute;
  left: 50px;
  top: 50%;
  transform: translateY(-110%);
}

.children {
  display: flex;
  align-items: flex-start;
  flex-flow: column nowrap;
}
</style>