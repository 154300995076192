import { newAxios as axios } from '../../main'
import qs from 'qs'
import env from '../../config/index'

//接口域名
// export let Url = 'http://zeng.yuedian.shop/api/'
export let prefix = env.BASE_URL + '/api'
// export let Url = '/api'

//post请求
export function post(url, data, callback) {
	data = qs.stringify(data)
	axios.post(prefix + url, data, {
	}).then((response) => {
		if (response.data.code == 401) {
			if (this.$route.query.token) {
				localStorage.setItem('token', this.$route.query.token)
			}
		}
		callback(response)
	})
}

//post请求
export function postXForm(url, data, callback) {
	const config = {
		headers: { 'Content-Type': 'multipart/form-data' } //这里是重点，需要和后台沟通好请求头，Content-Type不一定是这个值
	}
	axios.post(prefix + url, data, config).then((response) => {
		if (response.data.code == 401) {
			if (this.$route.query.token) {
				localStorage.setItem('token', this.$route.query.token)
			}
		}
		callback(response)
	})
}

//get请求
export function get(url, data, callback) {
	data = qs.stringify(data)
	axios.get(prefix + url, data, {
	}).then((response) => {
		if (response.data.code == 401) {
			if (this.$route.query.token) {
				localStorage.setItem('token', this.$route.query.token)
			}
			// var ua = navigator.userAgent.toLowerCase();
			// var isWeixin = ua.indexOf('micromessenger') != -1;
			// if (isWeixin) {
			// 	var u = encodeURIComponent(`http://api.wczstp.com/wanlshop/user/third?code=${this.$route.query.invite_code}`);
			// 	var url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxcf9c1895542251e9&redirect_uri=${u}&response_type=code&scope=snsapi_userinfo&state=${location.href.split('#/')[1]}#wechat_redirect`
			// 	window.location.href = url
			// } else {
			// 	this.$router.push({name: 'Login'})
			// }
		}
		callback(response)
	})
}