<template>
  <div class="sushi-diaoxian page">
    <div class="wrapper">
      <div class="factions">
        <div
          class="faction-wrapper"
          v-for="(item, index) in grid.factions"
          :key="index"
        >
          <div class="faction">
            <span>{{ item }}</span>
          </div>
        </div>
      </div>
      <div class="content">
        <div
          class="tree-content"
          :class="{ zhixi: type }"
          v-if="type == 2"
          :style="{
            transform: `translateX(${
              grid.isReverse ? 0 - grid.offset * 106 : grid.offset * 100
            }%) rotateY(${grid.isReverse ? '180deg' : '0deg'})`,
          }"
        >
          <sushi-diaoxian-item-zhixi
            v-for="(item, index) in grid.list"
            :key="item.id"
            :list="item.list"
            :fatherIndex="
              index === 0 ? -1 : getFatherPosition(grid.list[index - 1].list)
            "
            :isFirst="true"
            :style="{
              height: `calc(100% / 7)`,
            }"
          />
        </div>
        <div
          v-else
          class="tree-content"
          :style="{
            transform: `translateX(${
              grid.isReverse ? 0 - grid.offset * 106 : grid.offset * 99.8
            }%) rotateY(${grid.isReverse ? '180deg' : '0deg'})`,
          }"
        >
          <sushi-diaoxian-item
            v-for="(item, index) in grid.tree"
            :key="index"
            :item="item"
            :isFirst="true"
            :isReverse="grid.isReverse"
          />
        </div>
      </div>
    </div>
    <!-- <div v-for="(item, index) in grid" :key="index" class="grid">
      <div class="faction inner">
        <span>{{ splitText(item.name) }}</span>
      </div> -->
    <!-- <div
        class="inner name-wrapper"
        v-for="(person, index) in item.list"
        :style="`${index !== 0 && item.list[index - 1].child ? `margin-right: ${item.list[index - 1].child.length * 47 + (item.list[index - 1].spouseArrs ? item.list[index - 1].spouseArrs.length * 7 : 0)}px` : ''}`"
        :key="index"
      >
        <div class="name">
          <div
            class="has-father"
            :class="{
              'has-father-left':
                index !== 0 && isSameFather(person, item.list[index - 1]),
            }"
            
            v-if="person.is_zeng === 1 && person.father_id"
          ></div>
          {{ person.is_zeng === 1 ? person.name : person.username }}
          <div
            class="has-son"
            v-if="person.is_zeng === 1 && person.child && person.child.length"
          ></div>
        </div>
        <div
          class="name"
          v-for="(spouse, index) in person.spouseArrs"
          :key="index"
        >
          {{ spouse.is_zeng === 1 ? spouse.name : spouse.username }}
        </div>
      </div> -->
    <!-- </div> -->
  </div>
</template>

<script>
import SushiDiaoxianItem from "./SushiDiaoxianItem.vue";
import SushiDiaoxianItemZhixi from "./SushiDiaoxianItemZhixi.vue";

export default {
  name: "Sushi-Diaoxian",
  components: {
    SushiDiaoxianItem,
    SushiDiaoxianItemZhixi,
  },
  props: {
    grid: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      type: 1,
    };
  },
  computed: {
    emptyArr() {
      return new Array(10 - this.grid.length).fill(0).map((item, index) => {});
    },
  },
  mounted() {
    this.type = this.$route.query.type; // 家庭/直系
  },
  methods: {
    splitText(str) {
      if (!str) return "";
      let strArr = str.split("");
      let offset = 0;
      while (offset < str.length) {
        offset += 2;
        strArr.splice(offset + Math.floor(offset / 2) - 1, 0, "\n");
      }
      return strArr.join("");
    },
    isSameFather(item1, item2) {
      return item1.father_id === item2.father_id;
    },
    getFatherPosition(list) {
      return list.findIndex((item) => item.gridChild);
    },
    getSonPosition(list) {
      console.log(list[0].father_id);
      return list[0].father_id;
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  height: 100%;
}
.sushi-diaoxian {
  height: 100%;
  width: 100%;
  position: relative;
  background-color: #fff;
  display: flex;
  flex-flow: column nowrap;
  overflow: hidden;

  .factions {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    height: 100%;
    position: absolute;
    right: -5px;
    background-color: #fff;
    z-index: 100;
    padding-right: 20px;
    padding-left: 10px;

    // transform: scale(0.95);

    .faction-wrapper {
      height: calc(100% / 7);
      display: flex;
      align-items: center;
      flex: none;
    }

    .faction {
      display: flex;
      justify-content: center;
      align-items: center;
      flex: none;
      text-align: left;
      white-space: pre;
      writing-mode: vertical-rl;
      width: 50px;
      height: 113px;
      border: 2px solid #ac0404;
      border-radius: 7px;
      font-size: 24px;
      font-weight: 600;
      color: #ac0404;
    }
  }

  .content {
    width: 81vw;
    overflow: hidden;
    position: relative;
    margin-left: 40px;
    height: 100%;
    // transform: scale(0.95);
  }

  .tree-content {
    // margin: 20px;
    display: flex;
    flex-flow: row-reverse nowrap;
    position: relative;
    // margin-right: -50px;
    padding-top: 50px;

    // transform: scaleY(0.9);

    &.zhixi {
      flex-flow: column nowrap;
      height: 100%;
      padding: 0;
      margin: 0;
    }
  }
}
</style>