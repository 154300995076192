<template>
  <div class="catalog page">
    <h3 class="index-title">目录</h3>
    <div class="index-content">
      <div class="index-line" v-for="(item, index) in catalogData" :key="index">
        <div class="index-line-name">{{ item.title }}</div>
        <div class="dash"></div>
        <div class="index-line-num">{{ item.page }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Catalog",
  props: {
    catalogArr: {
      type: Array,
      default: () => []
    },
  },
  computed: {
    catalogData() {
      return this.catalogArr.reduce((acc, cur, index) => {
        const { title, size } = cur;
        const page = acc[index - 1] ? acc[index - 1].page + acc[index - 1].size : 1;
        // if (acc[cur.title]) {
        //   acc[cur.title].size += 1;
        // } else {
        //   acc[cur.title] = { page: 1 };
        // }
        acc = [...acc, { title, size, page }];
        return acc;
      }, []);
    }
  }
};
</script>

<style lang="scss" scoped>
.catalog {
  height: 100%;
  width: 100%;
  position: relative;
  background-color: #fff;
  // background-color: #ddd;
  // display: flex;
  // flex-flow: column nowrap;
  // justify-content: center;
  // align-items: center;

  .index-title {
    font-size: 32px;
    text-align: center;
  }

  .index-content {
    margin: 35px;
    margin-top: 60px;

    .index-line {
      width: 100%;
      display: flex;
      justify-content: space-between;
      font-size: 24px;
      margin-top: 46px;
    }

    .dash {
      border-bottom: 1px dashed #333;
      flex: 1;
      position: relative;
      top: -13px;
      margin: 0 20px;
    }
  }
}
</style>