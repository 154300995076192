<template>
  <div class="title page">
    <span class="title-text">{{ title }}</span>
  </div>
</template>

<script>
export default {
  name: "IndexTitle",
  props: {
    title: {
      type: String,
      default: ''
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  height: 100%;
  width: 100%;
  position: relative;
  background-color: #fff;
  // background-color: #ddd;
  display: flex;
  justify-content: center;
  align-items: center;

  .title-text {
    font-size: 100px;
    writing-mode: vertical-lr;
  }
}
</style>