<template>
  <div
    id="book"
    ref="book"
    class="book is-print"
    :class="{ a3: isA3 }"
    :key="componentKey"
  >
    <template v-if="!isA3">
      <dir
        class="book-page"
        v-for="(item, index) in menuArr.menuArr"
        :key="index"
      >
        <component
          :is="item.type"
          :index="index"
          :originIndex="item.originIndex"
          :pageIndex="pageIndex"
          :catalogArr="menuArr.catalogArr"
          :levelUnit="levelUnit"
          :levelOffset="levelOffset"
          v-bind="item.data"
        />

        <!-- <div
          class="side-title"
          :class="[getTitlePosition(index)]"
          v-if="item.type !== 'cover'"
        >
          <span class="jiapu-title">{{ puInfo.jpmc }}</span>
          <span class="jiapu-create-date">{{ puInfo.xpsj || "" }}</span>
        </div> -->
      </dir>
    </template>
    <template v-else>
      <dir
        class="book-page-a3"
        v-for="(item, index) in menuArr.menuArr"
        :key="index"
      >
        <component
          :is="`${item.type}-a3`"
          :index="index"
          :originIndex="item.originIndex"
          :pageIndex="pageIndex"
          :catalogArr="menuArr.catalogArr"
          :puInfo="puInfo"
          :levelUnit="levelUnit"
          :levelOffset="levelOffset"
          v-bind="item.data"
        />
      </dir>
    </template>
  </div>
</template>

<script>
import "@/utils/turn";
import apis from "@/apis/index";
import Cover from "@/components/BookPc/Cover.vue";
import Album from "@/components/BookPc/Album.vue";
import RichText from "@/components/BookPc/Text.vue";
import Sushi from "@/components/BookPc/Sushi.vue";
import SushiA3 from "@/components/BookPc/SushiA3.vue";
import Oushi from "@/components/BookPc/Oushi.vue";
import OushiA3 from "@/components/BookPc/OushiA3.vue";
import OushiDiaoxian from "@/components/BookPc/OushiDiaoxian.vue";
import OushiDiaoxianA3 from "@/components/BookPc/OushiDiaoxianA3.vue";
import SushiDiaoxian from "@/components/BookPc/SushiDiaoxian.vue";
import SushiDiaoxianA3 from "@/components/BookPc/SushiDiaoxianA3.vue";
import IndexTitle from "@/components/BookPc/Title.vue";
import Catalog from "@/components/BookPc/Catalog.vue";
import numberToChinese from "number-to-chinese-words";
import { chunk, cloneDeep } from "lodash";

const MOBILE_FONT_LIMIT = {
  OUSHI: 70,
  SUSHI: 75,
  TEXT: 600,
};

const PRINT_FONT_LIMIT = {
  OUSHI: 70,
  SUSHI: 75,
  TEXT: 580,
};

const PRINT_FONT_LIMIT_A3 = {
  OUSHI: 42,
  SUSHI: 75,
  TEXT: 580,
};

const TYPES = {
  ALBUM: "album", // 相册
  TEXT: "rich-text", // 文本
  COVER: "cover", // 封面
  CATALOG: "catalog", // 目录
  IDNEX: "index", // 检索表
  SUSHI: "sushi", // 苏式
  OUSHI: "oushi", // 欧式
  SUSHI_DIAOXIAN: "sushi-diaoxian", // 苏式吊线
  OUSHI_DIAOXIAN: "oushi-diaoxian", // 欧式吊线
  INDEX_TITLE: "index-title", // 标题
};

const PU_TYPE = {
  YIJIAQIN: 1,
  XIONGDIQIN: 2,
  ZHIXI: 3,
};

export default {
  name: "BookNew",
  components: {
    Cover,
    Album,
    Sushi,
    SushiA3,
    SushiDiaoxian,
    SushiDiaoxianA3,
    Oushi,
    OushiA3,
    OushiDiaoxianA3,
    OushiDiaoxian,
    IndexTitle,
    Catalog,
    RichText,
  },
  data() {
    return {
      isA3: false,
      selectedTemplate: "",
      startLevel: 0, // 开始代数
      downLevel: 10, // 向下代数
      levelUnit: "世", // 世代后缀
      levelOffset: 0, // 世代偏移量
      titlePosition: "right",
      treeId: undefined, // 中心人id
      startTreeId: undefined, // 开始人id
      creatId: undefined, // 谱id
      type: PU_TYPE.YIJIAQIN,
      isPrint: "1",
      isAll: 0,
      pageIndex: 1,
      yangshi: "sushi",
      turn: null,
      inited: false,
      componentKey: 1,
      puInfo: {},
      coverData: {
        type: TYPES.COVER,
        title: "封面",
        size: 1,
        data: {
          jpmc: "",
          zhubian: "",
          xpsj: "",
        },
      }, // 封面数据
      catalogData: {
        type: TYPES.CATALOG,
        title: "目录",
        size: 1,
        data: {},
      }, // 目录数据
      gridData: [],
      gridData1: [],
      gridDataSushiDiaoxian: [],
      gridDataOushiDiaoxian: [],
      menu: [
        // {
        //   type: TYPES.ALBUM,
        //   data: {
        //     title: "家族照",
        //   },
        //   pages: [
        //     {
        //       data: {
        //         arrangement: 4, // 后期可以 124
        //         images: {},
        //       },
        //     },
        //   ],
        // },
        // {
        //   type: TYPES.TEXT,
        //   data: {
        //     title: "家族照",
        //     content: "家族照",
        //   },
        // },
        // {
        //   type: TYPES.SUSHI,
        //   data: {
        //     title: "世系表",
        //   },
        //   pages: [],
        // },
        // {
        //   type: TYPES.OUSHI,
        //   data: {
        //     title: "世系表",
        //   },
        //   pages: [],
        // },
        // {
        //   type: TYPES.SUSHI,
        //   data: {
        //     title: "",
        //     arrangement: 4, // 后期可以 124
        //     images: {},
        //   },
        // },
      ],
      book: {
        title: "",
      },
      fontLimit: this.$route.query.isA3
        ? PRINT_FONT_LIMIT_A3
        : PRINT_FONT_LIMIT,
    };
  },
  computed: {
    menuArrA3() {
      return {
        ...this.menuArr,
        menuArr: chunk(this.menuArr.menuArr, 2),
      };
    },
    menuArr() {
      const catalogArr = [];
      const menuPage = this.menu.reduce((prev, curr, originIndex) => {
        const catalogData = {
          type: curr.type,
          title: curr.data.title || curr.data.customTitle,
          size: 0,
        };

        if (curr.pages) {
          catalogData.size += curr.pages.length;
          prev = prev.concat(
            curr.pages.map((item) => {
              return {
                ...item,
                originIndex,
                type: curr.type,
              };
            })
          );
        }

        if (
          curr.type === TYPES.OUSHI ||
          curr.type === TYPES.SUSHI ||
          curr.type === TYPES.SUSHI_DIAOXIAN ||
          curr.type === TYPES.OUSHI_DIAOXIAN
        ) {
          if (curr.type === TYPES.OUSHI) {
            // 添加欧式册谱
            catalogData.size += this.gridData1.length;
            prev = prev.concat(
              this.gridData1.map((item, index) => {
                return {
                  data: {
                    grid: item,
                    factions: this.gridData1faction[index],
                  },
                  type: TYPES.OUSHI,
                };
              })
            );
          }
          if (curr.type === TYPES.SUSHI) {
            // 添加苏式册谱
            catalogData.size += this.gridData.length;
            prev = prev.concat(
              this.gridData.map((item) => {
                return {
                  data: {
                    grid: item,
                  },
                  type: TYPES.SUSHI,
                };
              })
            );
          }
          if (curr.type === TYPES.SUSHI_DIAOXIAN) {
            let gridDataSushiDiaoxian = this.gridDataSushiDiaoxian;
            console.log(gridDataSushiDiaoxian);

            if (this.type == 3) {
              // 如果是直系谱 过滤掉多添加的分页
              gridDataSushiDiaoxian = this.gridDataSushiDiaoxian.filter(
                (item) => item.offset === 0
              );
            }

            if (this.isA3) {
              gridDataSushiDiaoxian = chunk(gridDataSushiDiaoxian, 2);
            }
            // 添加苏式吊线
            catalogData.size += gridDataSushiDiaoxian.length;
            prev = prev.concat(
              gridDataSushiDiaoxian.map((item) => {
                return {
                  data: {
                    grid: item,
                  },
                  type: TYPES.SUSHI_DIAOXIAN,
                };
              })
            );
          }
          if (curr.type === TYPES.OUSHI_DIAOXIAN) {
            catalogData.size += this.gridDataOushiDiaoxian.length;
            prev = prev.concat(
              this.gridDataOushiDiaoxian.map((item) => {
                return {
                  data: {
                    grid: item,
                  },
                  type: TYPES.OUSHI_DIAOXIAN,
                };
              })
            );
          }
        }
        catalogArr.push(catalogData);
        return prev;
      }, []);
      return {
        menuArr: [...menuPage],
        catalogArr,
      };
    },
  },
  mounted() {
    this.treeId = this.$route.query.treeId; // 人id
    this.startTreeId = this.$route.query.startTreeId; // 开始人id
    this.creatId = this.$route.query.creatId; // 谱id
    this.book_spectrum_id = this.$route.query.book_spectrum_id; // pc 册谱id
    this.type = parseInt(this.$route.query.type); // 家庭/直系
    this.isAll = this.$route.query.isAll; // 是否全部
    this.yangshi = this.$route.query.yangshi || "sushi"; // 欧式 苏式
    this.isPrint = this.$route.query.isPrint || 0;
    this.pageIndex = this.$route.query.pageIndex || 1;
    this.titlePosition = this.$route.query.titlePosition || "right";
    this.levelUnit = this.$route.query.levelUnit
      ? this.$route.query.levelUnit
      : "世";
    this.startLevel = this.$route.query.startLevel || 0;
    this.downLevel = this.$route.query.downLevel || 10;
    this.levelOffset = parseInt(this.$route.query.levelOffset) || 0;
    this.selectedTemplate = this.$route.query.selectedTemplate
      ? this.$route.query.selectedTemplate
      : "";
    this.isA3 = this.$route.query.isA3 || 0;
    this.getPuData();

    // if (this.selectedTemplate.includes("吊线")) {
    //   this.getDiaoxianData();
    // } else {

    // }
    this.getCepuData();

    this.getMenuData();
    // this.revicerPageSwitch();

    // setTimeout(() => {
    //   this.generatePdf()
    // }, 3000);
  },
  methods: {
    getTitlePosition(index) {
      if (index % 2 === 0) {
        return this.titlePosition;
      } else {
        return this.titlePosition === "left" ? "right" : "left";
      }
    },
    getPuData() {
      this.puInfo = this.$route.query;
    },
    getMenuData() {
      if (this.$route.query.single) {
        // 只显示谱
        const template = this.selectedTemplate;
        switch (template) {
          case "a4_欧式吊线":
          case "a3_欧式吊线": {
            this.menu = [
              {
                type: TYPES.OUSHI_DIAOXIAN,
                data: {
                  title: "欧式吊线",
                },
                pages: [],
              },
            ];
            break;
          }
          case "a4_苏式吊线":
          case "a3_苏式吊线": {
            this.menu = [
              {
                type: TYPES.SUSHI_DIAOXIAN,
                data: {
                  title: "苏式吊线",
                },
                pages: [],
              },
            ];
            break;
          }
          case "a4_欧式表格":
          case "a3_欧式表格": {
            this.menu = [
              {
                type: TYPES.OUSHI,
                data: {
                  title: "欧式表格",
                },
                pages: [],
              },
            ];
            break;
          }
          case "a4_苏式表格":
          case "a3_苏式表格": {
            this.menu = [
              {
                type: TYPES.SUSHI,
                data: {
                  title: "苏式表格",
                },
                pages: [],
              },
            ];
            break;
          }
        }
      } else {
        // apis
        //   .getPcCepuData({
        //     book_spectrum_id: this.creatId,
        //   })
        //   .then((data) => {
        //     try {
        //       this.menu = JSON.parse(data); //
        //       console.log(this.menu);
        //     } catch (error) {
        //       console.error(error);
        //     }
        //   });
      }
    },
    async getDiaoxianData() {
      let res = {};
      res = await apis.treeCepu({
        treeid: this.treeId,
        creatId: this.creatId,
        dataType: this.type === PU_TYPE.ZHIXI ? 2 : 1,
        ceng: this.$route.query.downLevel || 10,
        limit: 10000,
      });
      const { data, count } = res;
      const pai = {};
      const paiNameStr = [];
      let treeDataBeforeGrandfather = [];
      const gridData1 = [];
      const gridData1faction = [];
      // const data = cloneDeep(res);
      const sortedData = Object.values(data).sort((a, b) => {
        return a.factions - b.factions;
      });
      for (const item of sortedData) {
        // const item = data[id]; // 每一项
        // if (
        //   item.factions < this.startLevel ||
        //   item.factions > this.startLevel + this.downLevel
        // ) {
        //   // 大于范围的不显示
        //   continue;
        // }
        if (!pai[item.factions]) {
          const paiName = `${numberToChinese.toWords(
            item.factions + this.levelOffset
          )}${this.levelUnit}`;
          pai[item.factions] = {
            name: paiName, // 派名number-to-chinese-words
            id: item.factions, // 派id
            list: [], // 派列表
          };
          paiNameStr[item.factions - 1] = paiName;
          treeDataBeforeGrandfather[item.factions - 1] = [];
        }
        item["factionsName"] = paiNameStr[item.factions - 1];
        if (item.father_id && data[item.father_id]) {
          item.fatherName = data[item.father_id]?.name;
          item.fatherRankId = data[item.father_id]?.rank_id;
          // if (!data[item.father_id]) {
          //   console.log(item, item.father_id, data);
          // }

          if (data[item.father_id].child) {
            data[item.father_id].child.push(item);
            data[item.father_id].child.forEach((childItem) => {
              childItem.isOnly = false;
            });
          } else {
            data[item.father_id].child = [item];
            item.isOnly = true;
          }
        }
        pai[item.factions].list.push(item); // 根据派分类
        treeDataBeforeGrandfather[item.factions - 1].splice(
          item.rank_id > 0 ? item.rank_id - 1 : 0,
          0,
          item
        );

        // 欧式册谱数据
        const descLength = item.des?.length || 0;
        if (descLength < this.fontLimit.OUSHI) {
          // 介绍少于40
          gridData1.push(item);
          gridData1faction.push(item.factions);
        } else {
          let offset = 0;
          while (offset < descLength) {
            const newItem = offset === 0 ? { ...item } : {};
            newItem.des = item.des.substr(offset, this.fontLimit.OUSHI);
            gridData1.push(newItem);
            gridData1faction.push(item.factions);
            offset += this.fontLimit.OUSHI;
          }
        }
        // console.log(descLength)
        // prev = prev.concat(item);
        if (item.spouseArrs?.length) {
          // 如果有配偶
          gridData1.push(...item.spouseArrs);
          gridData1faction.push(
            ...item.spouseArrs.map((item) => item.factions)
          );
        }
        // 欧式谱end
        // gridData2.push(...item.spouseArrs)
      }

      treeDataBeforeGrandfather = treeDataBeforeGrandfather.filter(Boolean);

      // console.log(treeDataBeforeGrandfather)

      const gridData = treeDataBeforeGrandfather.reduce((prev, curr) => {
        curr
          .sort((a, b) => {
            if (a.fatherRankId !== b.fatherRankId) {
              return a.fatherRankId - b.fatherRankId;
            }
            if (a.sex !== b.sex) {
              return b.sex - a.sex;
            } else {
              return (
                (a.rank_id || Number.POSITIVE_INFINITY) -
                (b.rank_id || Number.POSITIVE_INFINITY)
              );
            }
          })
          .forEach((item) => {
            const descLength = item.des?.length || 0;
            if (descLength < this.fontLimit.SUSHI) {
              // 介绍少于40
              prev = prev.concat(item);
            } else {
              let offset = 0;
              while (offset < descLength) {
                const newItem = offset === 0 ? { ...item } : {};
                newItem.des = item.des.substr(offset, this.fontLimit.SUSHI);
                prev.push(newItem);
                offset += this.fontLimit.SUSHI;
              }
            }
            // console.log(descLength)
            // prev = prev.concat(item);
            if (item.spouseArrs?.length) {
              // 如果有配偶
              prev = prev.concat(item.spouseArrs);
            }
          });

        return prev;
      }, []);

      this.gridData = chunk(gridData, this.isA3 ? 18 : 10);
      this.gridData1 = chunk(gridData1, this.isA3 ? 24 : 8);
      this.gridData1faction = chunk(gridData1faction, this.isA3 ? 24 : 8);

      const chunkedSushiDiaoxianData = cloneDeep(chunk(Object.values(pai), 7))
        // .slice(0, 2)
        .reduce((prev, curr) => {
          const factions = curr.map((item) => item.name);
          const offsetMap = {};
          let offset = 0;
          curr.forEach((tcurr) => {
            tcurr.list.forEach((item, index) => {
              if (item.father_id && offsetMap[String(item.father_id)]) {
                offsetMap[String(item.id)] =
                  parseInt(offsetMap[String(item.father_id)]) + index;
              } else {
                offsetMap[String(item.id)] = index;
              }
            });
          });
          const tree = this.arrToTree(
            [...curr].reverse().reduce((tprev, tcurr) => {
              tcurr.list.forEach((item, index) => {
                if (index !== 0 && tcurr.list[index - 1]) {
                  if (tcurr.list[index - 1].child) {
                    let beforeOffset = 0;
                    tcurr.list[index - 1].child.forEach((childItem) => {
                      beforeOffset = Math.max(
                        offsetMap[String(childItem.id)] || 0,
                        beforeOffset
                      );
                    });
                    offsetMap[String(item.id)] = beforeOffset + 1;
                  } else {
                    offsetMap[String(item.id)] =
                      offsetMap[tcurr.list[index - 1].id] + 1;
                  }
                }
                if (item.child) {
                  const childOffset = this.getChildOffset(item, offsetMap);
                  offsetMap[String(item.id)] = childOffset;
                }
                offset = Math.max(offsetMap[String(item.id)] || 0, offset);
              });
              tprev.unshift(...tcurr.list);
              return tprev;
            }, [])
          );
          const tempArr = [];
          for (let i = 0; i <= Math.floor(offset / 8); i++) {
            tempArr.push({
              list: curr,
              factions,
              tree,
              offset: i,
            });
          }

          prev.push(...tempArr);

          return prev;
        }, []);
      // console.log(chunkedSushiDiaoxianData);
      this.gridDataSushiDiaoxian = chunkedSushiDiaoxianData;

      const chunkedOushiDiaoxianData = chunk(Object.values(pai), 5)
        // .slice(0, 2)
        .reduce((prev, curr) => {
          const factions = curr.map((item) => item.name);
          const offsetMap = {};
          let offset = 0;
          curr.forEach((tcurr) => {
            tcurr.list.forEach((item, index) => {
              if (item.father_id && offsetMap[String(item.father_id)]) {
                offsetMap[String(item.id)] =
                  parseInt(offsetMap[String(item.father_id)]) + index;
              } else {
                offsetMap[String(item.id)] = index;
              }
            });
          });
          const tree = this.arrToTree(
            [...curr].reverse().reduce((tprev, tcurr) => {
              tcurr.list.forEach((item, index) => {
                if (index !== 0 && tcurr.list[index - 1]) {
                  if (tcurr.list[index - 1].child) {
                    let beforeOffset = 0;
                    tcurr.list[index - 1].child.forEach((childItem) => {
                      beforeOffset = Math.max(
                        offsetMap[String(childItem.id)] || 0,
                        beforeOffset
                      );
                    });
                    offsetMap[String(item.id)] = beforeOffset + 1;
                  } else {
                    offsetMap[String(item.id)] =
                      offsetMap[tcurr.list[index - 1].id] + 1;
                  }
                }
                if (item.child) {
                  const childOffset = this.getChildOffset(item, offsetMap);
                  offsetMap[String(item.id)] = childOffset;
                }
                offset = Math.max(offsetMap[String(item.id)] || 0, offset);
              });
              tprev.unshift(...tcurr.list);
              return tprev;
            }, [])
          );
          const tempArr = [];
          for (let i = 0; i <= Math.floor(offset / 15); i++) {
            tempArr.push({
              ...curr,
              factions,
              tree,
              offset: i,
              // isReverse: i % 2 === 1,
            });
          }
          prev.push(...tempArr);

          return prev;
        }, []);

      this.gridDataOushiDiaoxian = chunkedOushiDiaoxianData;

      // console.log(this.gridData1);
    },
    async getCepuData() {
      // 册谱数据
      let res = null;
      if (this.type === PU_TYPE.XIONGDIQIN) {
        console.log("XIONGDIQIN", this.type);
        res = await apis.xiongdiqinData({
          genealogyid: this.creatId,
          beginid: this.startTreeId,
          beginceng: this.startLevel,
          endceng: this.downLevel,
        });
      }

      if (this.type === PU_TYPE.YIJIAQIN) {
        console.log("YIJIAQIN", this.type);
        res = await apis.yijiaqinData({
          genealogyid: this.creatId,
          beginid: this.startTreeId,
          beginceng: this.startLevel,
          endceng: this.downLevel,
        });
      }

      if (this.type === PU_TYPE.ZHIXI) {
        res = await apis.zhixiData({
          beginid: this.startTreeId,
          endid: this.treeId,
        });
      }

      if (this.selectedTemplate.includes("苏式表格")) {
        // start 苏轼表格
        const gridData = res.data.reduce((prev, item) => {
          // const lastFactions = prev[prev.length - 1]?.factions || -1;
          // if (item.factions !== lastFactions) {
          //   prev = prev.concat({
          //     des: this.getFactionName(item)
          //   });
          // }
          const descLength = item.des?.length || 0;
          if (descLength < this.fontLimit.SUSHI) {
            // 介绍少于40
            prev = prev.concat(item);
          } else {
            let offset = 0;
            while (offset < descLength) {
              const newItem = offset === 0 ? { ...item } : {};
              newItem.des = item.des.substr(offset, this.fontLimit.SUSHI);
              prev.push(newItem);
              offset += this.fontLimit.SUSHI;
            }
          }
          // console.log(descLength)
          // prev = prev.concat(item);
          if (item.spouseArrs?.length) {
            // 如果有配偶
            item.spouseArrs.forEach((itemSpouse) => {
              const descLength = itemSpouse.des?.length || 0;
              if (descLength < this.fontLimit.SUSHI) {
                // 介绍少于40
                prev = prev.concat(itemSpouse);
              } else {
                let offset = 0;
                while (offset < descLength) {
                  const newItem = offset === 0 ? { ...itemSpouse } : {};
                  newItem.des = itemSpouse.des.substr(offset, this.fontLimit.SUSHI);
                  prev.push(newItem);
                  offset += this.fontLimit.SUSHI;
                }
              }
            });
            // prev = prev.concat(item.spouseArrs);
          }

          return prev;
        }, []);

        this.gridData = chunk(gridData, this.isA3 ? 18 : 10);
        // end 苏轼表格
      }

      if (this.selectedTemplate.includes("欧式表格")) {
        // 欧式册谱数据
        const gridData1 = [];
        const gridData1faction = [];
        res.data.forEach((item) => {
          const descLength = item.des?.length || 0;
          if (descLength < this.fontLimit.OUSHI) {
            // 介绍少于40
            gridData1.push(item);
            gridData1faction.push(item.factions);
          } else {
            let offset = 0;
            while (offset < descLength) {
              const newItem = offset === 0 ? { ...item } : {};
              newItem.des = item.des.substr(offset, this.fontLimit.OUSHI);
              gridData1.push(newItem);
              gridData1faction.push(item.factions);
              offset += this.fontLimit.OUSHI;
            }
          }
          // console.log(descLength)
          // prev = prev.concat(item);
          if (item.spouseArrs?.length) {
            // 如果有配偶
            item.spouseArrs.forEach((itemSpouse) => {
              const descLength = itemSpouse.des?.length || 0;
              if (descLength < this.fontLimit.OUSHI) {
                // 介绍少于40
                gridData1.push(itemSpouse);
                gridData1faction.push(itemSpouse.factions);
              } else {
                let offset = 0;
                while (offset < descLength) {
                  const newItem = offset === 0 ? { ...itemSpouse } : {};
                  newItem.des = itemSpouse.des.substr(
                    offset,
                    this.fontLimit.OUSHI
                  );
                  gridData1.push(newItem);
                  gridData1faction.push(itemSpouse.factions);
                  offset += this.fontLimit.OUSHI;
                }
              }
            });
            // gridData1.push(...item.spouseArrs);
            // gridData1faction.push(
            //   ...item.spouseArrs.map((item) => item.factions)
            // );
          }
        });

        this.gridData1 = chunk(gridData1, this.isA3 ? 24 : 8);
        this.gridData1faction = chunk(gridData1faction, this.isA3 ? 24 : 8);
        // 欧式谱end
      }

      if (this.selectedTemplate.includes("苏式吊线")) {
        const pai = {};
        const data = {};
        res.data.forEach((item) => {
          if (!pai[item.factions]) {
            const paiName = `${numberToChinese.toWords(
              item.factions + this.levelOffset
            )}${this.levelUnit}`;

            pai[item.factions] = {
              name: paiName, // 派名number-to-chinese-words
              id: item.factions, // 派id
              list: [], // 派列表
            };
          }
          if (item.is_zeng === 1) {
            // 是直系
            const father = res.data.find((it) => it.id === item.father_id);
            if (father) {
              if (father.child) {
                father.child.push(item);
                father.child.forEach((childItem) => {
                  childItem.isOnly = false;
                });
              } else {
                father.child = [item];
                item.isOnly = true;
              }
            } else {
              const mother = res.data.find((it) => it.id === item.mother_id);
              if (mother) {
                if (mother.child) {
                  mother.child.push(item);
                  mother.child.forEach((childItem) => {
                    childItem.isOnly = false;
                  });
                } else {
                  mother.child = [item];
                  item.isOnly = true;
                }
              }
            }
          }
          pai[item.factions].list.push(item);
        });

        const chunkedSushiDiaoxianData = cloneDeep(chunk(Object.values(pai), 7))
          // .slice(0, 2)
          .reduce((prev, curr) => {
            const factions = curr.map((item) => item.name);
            const offsetMap = {};
            // let offset = 0;
            // console.log(curr);
            // curr.forEach((tcurr) => {
            //   tcurr.list.forEach((item, index) => {
            //     // if (item.father_id && offsetMap[String(item.father_id)]) {
            //     //   offsetMap[String(item.id)] =
            //     //     parseInt(offsetMap[String(item.father_id)]) + index;
            //     //     console.log('father', item.id, item.father_id, parseInt(offsetMap[String(item.father_id)]), index)
            //     // } else if (
            //     //   item.mother_id &&
            //     //   offsetMap[String(item.mother_id)]
            //     // ) {
            //     //   offsetMap[String(item.id)] =
            //     //     parseInt(offsetMap[String(item.mother_id)]) + index;
            //     //     console.log('mother', item.id, item.mother_id, parseInt(offsetMap[String(item.mother_id)]), index)
            //     // } else {
            //       offsetMap[String(item.id)] = index;
            //       console.log('no', item.id, index)
            //     // }
            //     item.name = item.name + offsetMap[String(item.id)];
            //   });
            // });
            // console.log(JSON.stringify(offsetMap))
            const tree = this.arrToTree(
              [...curr].reverse().reduce((tprev, tcurr) => {
                // tcurr.list.forEach((item, index) => {
                //   if (index !== 0 && tcurr.list[index - 1]) {
                //     if (tcurr.list[index - 1].child) {
                //       let beforeOffset = 0;
                //       tcurr.list[index - 1].child.forEach((childItem) => {
                //         beforeOffset = Math.max(
                //           offsetMap[String(childItem.id)] || 0,
                //           beforeOffset
                //         );
                //       });
                //       // offsetMap[String(item.id)] = beforeOffset + 1;
                //     } else {
                //       // offsetMap[String(item.id)] =
                //       //   offsetMap[tcurr.list[index - 1].id] + 1;
                //     }
                //   }
                //   if (item.child) {
                //     const childOffset = this.getChildOffset(item, offsetMap);
                //     // offsetMap[String(item.id)] = childOffset;
                //   }
                //   // item.name = item.name + offsetMap[String(item.id)];
                //   // item.name = item.name + item.father_id + '.' + item.id
                //   offset = Math.max(offsetMap[String(item.id)] || 0, offset);
                // });
                tprev.unshift(...tcurr.list);
                return tprev;
              }, [])
            );
            const tempArr = [];
            console.log(tree);
            const offset = Math.max(
              ...tree.map((treeItem, idx) => this.getItemOffset(treeItem, idx))
            );
            console.log(offset);
            // tree.forEach((treeItem, index) => {
            //   const offset = this.getItemOffset(treeItem, index);
            //   console.log(offset)
            // });

            if (this.isA3 && this.type != 3) {
              for (let i = 0; i <= Math.ceil(offset / 17); i += 2) {
                tempArr.push({
                  list: curr,
                  factions,
                  tree,
                  offset: i,
                });
                tempArr.push({
                  list: curr,
                  factions,
                  tree,
                  offset: i + 1,
                });
              }
            } else {
              for (let i = 0; i <= Math.floor(offset / 8); i++) {
                tempArr.push({
                  list: curr,
                  factions,
                  tree,
                  offset: i,
                });
              }
            }

            prev.push(...tempArr);

            return prev;
          }, []);
        // console.log(chunkedSushiDiaoxianData);
        this.gridDataSushiDiaoxian = chunkedSushiDiaoxianData;

        // console.log(this.gridDataSushiDiaoxian);
      }

      if (this.selectedTemplate.includes("欧式吊线")) {
        const pai = {};
        const data = {};
        res.data.forEach((item) => {
          if (!pai[item.factions]) {
            const paiName = `${numberToChinese.toWords(
              item.factions + this.levelOffset
            )}${this.levelUnit}`;

            pai[item.factions] = {
              name: paiName, // 派名number-to-chinese-words
              id: item.factions, // 派id
              list: [], // 派列表
            };
          }
          if (item.is_zeng === 1) {
            // 是直系
            const father = res.data.find((it) => it.id === item.father_id);
            if (father) {
              if (father.child) {
                father.child.push(item);
                father.child.forEach((childItem) => {
                  childItem.isOnly = false;
                });
              } else {
                father.child = [item];
                item.isOnly = true;
              }
            } else {
              const mother = res.data.find((it) => it.id === item.mother_id);
              if (mother) {
                if (mother.child) {
                  mother.child.push(item);
                  mother.child.forEach((childItem) => {
                    childItem.isOnly = false;
                  });
                } else {
                  mother.child = [item];
                  item.isOnly = true;
                }
              }
            }
          }
          pai[item.factions].list.push(item);
        });

        const chunkedOushiDiaoxianData = chunk(Object.values(pai), 5)
          // .slice(0, 2)
          .reduce((prev, curr) => {
            const factions = curr.map((item) => item.name);
            const offsetMap = {};
            let offset = 0;
            curr.forEach((tcurr) => {
              tcurr.list.forEach((item, index) => {
                if (item.father_id && offsetMap[String(item.father_id)]) {
                  offsetMap[String(item.id)] =
                    parseInt(offsetMap[String(item.father_id)]) + index;
                } else if (
                  item.mother_id &&
                  offsetMap[String(item.mother_id)]
                ) {
                  offsetMap[String(item.id)] =
                    parseInt(offsetMap[String(item.father_id)]) + index;
                } else {
                  offsetMap[String(item.id)] = index;
                }
              });
            });
            const tree = this.arrToTree(
              [...curr].reverse().reduce((tprev, tcurr) => {
                tcurr.list.forEach((item, index) => {
                  if (index !== 0 && tcurr.list[index - 1]) {
                    if (tcurr.list[index - 1].child) {
                      let beforeOffset = 0;
                      tcurr.list[index - 1].child.forEach((childItem) => {
                        beforeOffset = Math.max(
                          offsetMap[String(childItem.id)] || 0,
                          beforeOffset
                        );
                      });
                      offsetMap[String(item.id)] = beforeOffset + 1;
                    } else {
                      offsetMap[String(item.id)] =
                        offsetMap[tcurr.list[index - 1].id] + 1;
                    }
                  }
                  if (item.child) {
                    const childOffset = this.getChildOffset(item, offsetMap);
                    offsetMap[String(item.id)] = childOffset;
                  }

                  // item.name = item.name + offsetMap[String(item.id)];

                  offset = Math.max(offsetMap[String(item.id)] || 0, offset);
                });
                tprev.unshift(...tcurr.list);
                return tprev;
              }, [])
            );
            const tempArr = [];
            for (let i = 0; i <= Math.floor(offset / 15); i++) {
              tempArr.push({
                ...curr,
                factions,
                tree,
                offset: i,
                // isReverse: i % 2 === 1,
              });
            }
            prev.push(...tempArr);

            return prev;
          }, []);

        console.log(chunkedOushiDiaoxianData);
        this.gridDataOushiDiaoxian = chunkedOushiDiaoxianData;
      }
    },
    getFactionName(item) {
      return item.is_zeng === 1
        ? `${numberToChinese.toWords(item.factions + this.levelOffset)}${
            this.levelUnit
          }`
        : "";
    },

    getChildOffset(item, offsetMap) {
      let childOffset = offsetMap[String(item.id)] || 0;
      if (item.child) {
        item.child.forEach((childItem) => {
          const offset = this.getChildOffset(childItem, offsetMap);
          childOffset = Math.max(offset, childOffset);
          // offset = offsetMap[item.id];
          // item.name = item.name + offset;
        });
      }
      return childOffset;
    },
    getItemOffset(item, baseOffset = 0) {
      let offset = baseOffset;
      if (item.gridChild) {
        let maxChildOffset = 0;
        item.gridChild.forEach((childItem, index) => {
          // const baseOffset1 = index === 0 ? 0 : this.getItemOffset(item.gridChild[index - 1], 0)
          // console.log(baseOffset1)
          const childOffset = this.getItemOffset(childItem, index);
          const totalOffset =
            index === 0
              ? 0
              : this.getItemOffset(item.gridChild[index - 1], index - 1);
          maxChildOffset = Math.max(childOffset + totalOffset, maxChildOffset);
          // offset = offsetMap[item.id];
        });
        offset += maxChildOffset;
      }
      // item.name = item.name + '.' + baseOffset + '.' + offset;
      // console.log(item.name, offset, item, item.child)
      return offset;
    },
    arrToTree(data) {
      let tree = [];
      if (!Array.isArray(data)) {
        return tree;
      }
      // 将数组转换成对象（键值对），将ID作为属性名，原来的数组里的对象作为属性值
      let map = {};
      data.forEach((item) => {
        map[item.id] = item;
      });
      // 通过对象的属性名（ID）来找到父级节点，将存到map里的对象取出来放到父级节点的childere数组中
      data.forEach((item) => {
        let parent = map[item.father_id];
        // if (parent && parent.is_zeng !== 1) {
        //   parent = map[item.mother_id]
        // }

        if (parent) {
          (parent.gridChild || (parent.gridChild = [])).push(item);
          if (parent.gridChild.length === 1) {
            parent.gridChild.forEach((childItem) => {
              childItem.isOnly = true;
            });
          } else {
            parent.gridChild.forEach((childItem) => {
              childItem.isOnly = false;
            });
          }
          parent.gridChild = parent.gridChild.sort((a, b) => {
            if (a.sex !== b.sex) {
              return b.sex - a.sex;
            } else {
              return (
                (a.rank_id || Number.POSITIVE_INFINITY) -
                (b.rank_id || Number.POSITIVE_INFINITY)
              );
            }
          });
        } else if (item.is_zeng === 1) {
          tree.push(item);
        }
      });
      return tree;
    },
  },
};
</script>

<style lang="scss" scoped>
.book {
  // width: 750px;
  // height: 1061px;
  // border: 1px solid #ddd;
  // &::-webkit-scrollbar {
  //   display: none;
  // }

  .book-page {
    // a4比例
    width: 726px;
    height: 1064px;
    margin: 0;
    padding: 0;
    position: relative;

    .side-title {
      position: absolute;
      font-size: 26px;

      .jiapu-create-date {
        margin-top: 150px;
        font-size: 17px;
      }

      &.right {
        right: 50px;
        top: 200px;
        writing-mode: vertical-rl;
      }

      &.left {
        left: 50px;
        top: 200px;
        writing-mode: vertical-rl;
      }
    }
  }

  .book-page-a3 {
    width: 1360px;
    height: 1060px;
    margin: 0;
    padding: 0;
  }

  // &.is-print {
  //   width: 100%;
  //   height: 100%;
  //   margin-top: -0.5px;

  //   & > div {
  //     width: 750px;
  //     height: 1063.5px;
  //     // padding: 20px;
  //     border: 2px solid #444;
  //     transform: scale(0.9) translateY(20px);
  //     // position: relative;
  //     // box-sizing: border-box;
  //     // background-color: #ddd;
  //     // border: 1px solid #b5b5b5;
  //     // margin: 40px 0;
  //   }
  // }

  // & > div {
  //   height: 100%;
  //   width: 100%;
  //   // background-color: #ddd;
  // }
}
</style>